import { assert } from '@shared/utils/utilities'

import { ICreateMarketOffer } from '../models'
import { ICreateMarketOfferRequest, ICreateStopRequest } from '../requests'

export const parseUsCreateMyMarketOffer = (
  request: ICreateMarketOffer,
): ICreateMarketOfferRequest => {
  const serverReadyRequest: Partial<ICreateMarketOfferRequest> = {
    ...request,
    published: true,
    carrierPrice: request.publishedPrice,
    carrierPriceCurrency: request.publishedPriceCurrency,
    loadingMeters: String(request.loadingMeters),
    tags: ['broker-load'],
    stops: [],
  }
  for (const stop of request.stops) {
    const { ...ns }: Partial<ICreateStopRequest> = stop
    if (stop.time && stop.date) {
      const time = new Date(stop.time)
      const date = new Date(stop.date)
      date.setHours(time.getHours(), time.getMinutes(), 0)
      ns.date = date.toISOString()
    }
    if (stop.timeTo && stop.dateTo) {
      const time = new Date(stop.timeTo)
      const date = new Date(stop.dateTo)
      date.setHours(time.getHours(), time.getMinutes(), 0)
      ns.dateTo = date.toISOString()
    }
    assert(!!serverReadyRequest.stops, 'Stops array must exist when parsing offer for publishing')
    serverReadyRequest.stops.push(ns as ICreateStopRequest)
  }

  return serverReadyRequest as ICreateMarketOfferRequest
}
