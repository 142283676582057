import { assert } from '@shared/utils/utilities'

import { euMyMarketOffersDefaults, euMyMarketOffersStopDefaults } from './euDefaults'
import { usMyMarketOffersDefaults, usMyMarketOffersStopDefaults } from './usDefaults'

/**
 * Gets the default values for market offers based on the specified region
 *
 * @param {string} region - The region for which to get the market offers defaults
 * Should be 'EU' for European market offers or any other string for US market offers
 * @returns {Partial<any>} The default values for market offers
 * This object may include default values for various properties of market offer objects
 *
 * @throws {Error} Will throw an error if the region is an empty string
 *
 * @todo Change to a more specific interface for market offers once the market module has its own load definition
 */
export const getMyMarketOffersDefaults = (region: string): Partial<any> => {
  assert(region !== '', 'Region cannot be an empty string')
  if (region === 'EU') {
    return euMyMarketOffersDefaults
  }

  return usMyMarketOffersDefaults
}

/**
 * Gets the default stop values for market offers based on the specified region
 *
 * @param {string} region - The region for which to get the market offers stop defaults
 * Should be 'EU' for European market offers or any other string for US market offers
 * @returns {Partial<any>} The default stop values for market offers
 * This object may include default stop values for various properties of market offer objects
 *
 * @throws {Error} Will throw an error if the region is an empty string
 *
 * @todo Change to a more specific interface for market offers once the market module has its own load definition
 */
export const getMyMarketOffersStopDefaults = (region: string): Partial<any> => {
  assert(region !== '', 'Region cannot be an empty string')
  if (region === 'EU') {
    return euMyMarketOffersStopDefaults
  }

  return usMyMarketOffersStopDefaults
}
