import { FC, SyntheticEvent } from 'react'
import { t } from 'i18next'
import CountryFlag from '../CountryFlag'
import { parseCountriesForSelect, parseCountriesForFilter, ICountrySelectOption } from './utils'

import { Autocomplete, MenuItem, TextField } from '@mui/material'

interface ICountrySelectProps {
  value: string
  onChange: (e: SyntheticEvent<Element, Event>, option: ICountrySelectOption | null) => void
}

const CountrySelect: FC<ICountrySelectProps> = ({ value, onChange: handleChange }) => {
  return (
    <Autocomplete
      disablePortal
      value={parseCountriesForSelect().find((option) => option.value === value) ?? null}
      onChange={handleChange}
      options={parseCountriesForSelect()}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Common:country')}
        />
      )}
      renderOption={(props, option) => {
        return (
          <MenuItem
            {...props}
            key={option.value}
          >
            <CountryFlag countryName={option.value} />({option.label}){' '}
            {t(`Common:countries.${option.value}`)}
          </MenuItem>
        )
      }}
      getOptionLabel={() => t(`Common:countries.${value}`)}
      filterOptions={parseCountriesForFilter}
    />
  )
}

export default CountrySelect
