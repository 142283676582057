import appConfig from '@/config'
import { getRegion } from '@/shared/utils/formatters'

import { ICountryConfig } from '../types'
import { ICompanyPartial } from './models'

const country: string | null = localStorage.getItem('country')

export const initCompayDialogRequest = () => {
  return {
    name: '',
    fullName: '',
    vatId: '',
    regon: '',
    phone: '',
    email: '',
    countryCode: '',
    city: '',
    street: '',
    zipCode: '',
    correspondence: '',
    categories: [],
    freightCurrency:
      (appConfig[getRegion(country!) as keyof typeof appConfig] as ICountryConfig)
        .defaultCurrency ?? 'USD',
    paymentCurrency:
      (appConfig[getRegion(country!) as keyof typeof appConfig] as ICountryConfig)
        .defaultCurrency ?? 'USD',
    defaultVat: '',
    incomingPaymentValue: 0,
    incomingPaymentCurrency:
      (appConfig[getRegion(country!) as keyof typeof appConfig] as ICountryConfig)
        .defaultCurrency ?? 'USD',
    outgoingPaymentValue: 0,
    outgoingPaymentCurrency:
      (appConfig[getRegion(country!) as keyof typeof appConfig] as ICountryConfig)
        .defaultCurrency ?? 'USD',
    incomingInvoicePaymentDays: 7,
    outgoingInvoicePaymentDays: 30,
    description: '',
    isSuspended: false,
    suspendedDescription: '',
  } as ICompanyPartial
}
