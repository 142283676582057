import { Dispatch, SetStateAction } from 'react'
import { ITheme } from '@pkg/sokarUI'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styles from './transportsSelection.module.scss'
import { getDateFormat } from '@shared/utils/regionals'
import { useGetLoadQuery } from '@features/loads/loadsApiSlice'
import { ILoad } from '@pkg/loads/models'

import {
  Box,
  Stack,
  Paper,
  useTheme,
  List,
  ListItem,
  Checkbox,
  Typography,
  TextField,
  Divider,
  CircularProgress,
} from '@mui/material'

interface TransportSelectionProps {
  driverId: string | undefined
  value: string[]
  setValue: Dispatch<SetStateAction<string[]>>
  setLoads: Dispatch<SetStateAction<ILoad[]>>
}

const TransportSelection = ({ ...props }: TransportSelectionProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()

  const { data: transports, isFetching: isTransportsFetching } = useGetLoadQuery(
    { published: false, archived: false, driverId: props.driverId, tags: 'invoiced' },
    { skip: !props.driverId },
  )

  const handleTransportStatusChange = (load: ILoad): void => {
    props.setValue((prevSelectedIds) => {
      if (prevSelectedIds.includes(load.id)) {
        return prevSelectedIds.filter((item) => item !== load.id)
      } else {
        return [...prevSelectedIds, load.id]
      }
    })
    props.setLoads((prevSelectedLoads) => {
      if (prevSelectedLoads.includes(load)) {
        return prevSelectedLoads.filter((item) => item !== load)
      } else {
        return [...prevSelectedLoads, load]
      }
    })
  }

  return (
    <Box
      component={Paper}
      className={styles.wrapper}
      sx={{ bgcolor: appTheme.palette.background.default }}
    >
      <Typography
        className={styles.title}
        sx={{ color: appTheme.palette.primary.main }}
      >
        {t('Accounting:transports')}
      </Typography>
      <Stack
        direction={'row'}
        spacing={2}
        className={styles.tripRow}
      >
        <Typography>{t('Accounting:totalTrip')}:</Typography>
        <TextField
          type={'number'}
          disabled={!props.driverId}
        />
      </Stack>
      <Divider />
      {isTransportsFetching && (
        <Box className={styles.loader}>
          <CircularProgress size={'6rem'} />
        </Box>
      )}
      <List>
        {transports?.map((transport) => (
          <ListItem className={styles.listRow}>
            <Checkbox
              className={styles.checkbox}
              checked={props.value.includes(transport.id)}
              onChange={() => handleTransportStatusChange(transport)}
            />
            <Box className={styles.stopCell}>
              <Typography>{transport.externalId}</Typography>
            </Box>
            <Box className={styles.stopCell}>
              <Typography>
                {transport.stops[0].countryAbbreviation} {transport.stops[0].city}
              </Typography>
              <Typography>{dayjs(transport.stops[0].date).format(getDateFormat())}</Typography>
            </Box>
            <Box className={styles.stopCell}>
              <Typography>
                {transport.stops[transport.stops.length - 1].countryAbbreviation}{' '}
                {transport.stops[transport.stops.length - 1].city}
              </Typography>
              <Typography>
                {dayjs(transport.stops[transport.stops.length - 1].date).format(getDateFormat())}
              </Typography>
            </Box>
            <Typography>
              {transport.shipperPriceCurrency} {transport.shipperPrice}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default TransportSelection
