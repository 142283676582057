import { apiSlice } from '@/api/apiSlice'
import { ITrailer, ITruck } from '@/pkg/equipment/models'
import {
  ICreateTrailerRequest,
  IReserveEquipmentRequest,
  IUpdateTrailerRequest,
  ICreateTruckRequest,
  IUpdateTruckRequest,
} from '@/pkg/equipment/requests'

export const equipmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrailer: builder.query<
      ITrailer[],
      { trailerId?: string; companyId?: string; archived?: boolean; carrierId?: string }
    >({
      query: ({ trailerId, companyId, archived }) => ({
        url: '/equipment/trailer',
        params: { trailerId, companyId, archived },
      }),
      transformResponse: (response: { trailers: ITrailer[] }) => response.trailers,
    }),
    createTrailer: builder.mutation<ITrailer, ICreateTrailerRequest>({
      query: (trailer) => ({
        url: '/equipment/trailer',
        method: 'POST',
        body: trailer,
      }),
      transformResponse: (response: { trailer: ITrailer }) => response.trailer,
    }),
    updateTrailer: builder.mutation<ITrailer, IUpdateTrailerRequest>({
      query: (trailer) => ({
        url: '/equipment/trailer',
        method: 'PUT',
        body: trailer,
      }),
      transformResponse: (response: { trailer: ITrailer }) => response.trailer,
    }),
    getTrailerHistory: builder.query<any[], string>({
      query: (trailerId) => {
        const baseURL = '/equipment/trailer/events'
        if (trailerId) {
          return { url: `${baseURL}?id=${trailerId}` }
        }
        return baseURL
      },
      transformResponse: (response: { events: any[] }) => response.events,
    }),
    getTrailerCategories: builder.query<string[], null>({
      query: () => ({
        url: '/equipment/trailer/categories',
      }),
      transformResponse: (response: { name: string[] }) => response.name,
    }),
    generateTrailerQrCode: builder.mutation<{ qrCode: string }, string>({
      query: (trailerId) => ({
        url: `/equipment/trailer/qr-code?trailerId=${trailerId}`,
        method: 'GET',
      }),
    }),
    reserveTrailer: builder.mutation<IReserveEquipmentRequest, IReserveEquipmentRequest>({
      query: (reservation) => ({
        url: '/equipment/trailer/reserve',
        method: 'PUT',
        body: reservation,
      }),
    }),
    searchTrailer: builder.query<
      ITrailer[],
      { phrase: string; companyId?: string; archived?: boolean; carrierId?: string }
    >({
      query: ({ phrase, companyId, archived, carrierId }) => ({
        url: `/equipment/trailer/search`,
        params: { phrase, companyId, archived, carrierId },
      }),
      transformResponse: (response: { trailers: ITrailer[] }) => response.trailers,
    }),
    deleteTrailer: builder.mutation<{ id: string }, string>({
      query: (trailerId) => ({
        url: `/equipment/trailer/${trailerId}`,
        method: 'DELETE',
      }),
    }),
    getTruck: builder.query<
      ITruck[],
      { truckId?: string; companyId?: string; archived?: boolean; carrierId?: string }
    >({
      query: ({ truckId, companyId, archived }) => ({
        url: '/equipment/truck',
        params: { truckId, companyId, archived },
      }),
      transformResponse: (response: { trucks: ITruck[] }) => response.trucks,
    }),
    createTruck: builder.mutation<ITruck, ICreateTruckRequest>({
      query: (truck) => ({
        url: '/equipment/truck',
        method: 'POST',
        body: truck,
      }),
      transformResponse: (response: { truck: ITruck }) => response.truck,
    }),
    updateTruck: builder.mutation<ITruck, IUpdateTruckRequest>({
      query: (truck) => ({
        url: '/equipment/truck',
        method: 'PUT',
        body: truck,
      }),
      transformResponse: (response: { truck: ITruck }) => response.truck,
    }),
    reserveTruck: builder.mutation<IReserveEquipmentRequest, IReserveEquipmentRequest>({
      query: (reservation) => ({
        url: '/equipment/truck/reserve',
        method: 'PUT',
        body: reservation,
      }),
    }),
    searchTruck: builder.query<
      ITruck[],
      { phrase: string; companyId?: string; archived?: boolean; carrierId?: string }
    >({
      query: ({ phrase, companyId, archived, carrierId }) => ({
        url: `/equipment/truck/search`,
        params: { phrase, companyId, archived, carrierId },
      }),
      transformResponse: (response: { trucks: ITruck[] }) => response.trucks,
    }),
    deleteTruck: builder.mutation<{ id: string }, string>({
      query: (truckId) => ({
        url: `/equipment/truck/${truckId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetTrailerQuery,
  useCreateTrailerMutation,
  useUpdateTrailerMutation,
  useGetTrailerHistoryQuery,
  useGenerateTrailerQrCodeMutation,
  useReserveTrailerMutation,
  useGetTrailerCategoriesQuery,
  useDeleteTrailerMutation,
  useGetTruckQuery,
  useCreateTruckMutation,
  useUpdateTruckMutation,
  useReserveTruckMutation,
  useDeleteTruckMutation,
  useSearchTrailerQuery,
  useSearchTruckQuery,
} = equipmentApiSlice
