import { useState, ChangeEvent, useEffect } from 'react'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, useFieldArray, FieldErrors } from 'react-hook-form'
import { brokerInstancePublishedLoadValidator } from '@pkg/loads/validators'
import { ICreateCarrierInstanceLoadRequest } from '@pkg/loads/requests'
import useAlert from '@hooks/useAlert'
import { initICreateCarrierLoadRequest, initICreateStopRequest } from '@pkg/loads/initializers'
import { useCreateLoadMutation } from '@features/loads/loadsApiSlice'
import CompanySearch from '@/lib/CompanySearch'
import TrailerSearch from '@shared/components/trailerSearch'
import TruckSearch from '@shared/components/truckSearch'
import { parseError } from '@shared/utils/formatters'
import { ICity } from '@pkg/loads/models'
import { countryCodes } from '@pkg/countryCodes'
import MultipleDriversSearch from '@shared/components/multipleDriversSearch'
import CityField from '@lib/CityField'
import PhoneNumberField from '@lib/PhoneNumberField'
import useOwnUser from '@hooks/useOwnUser'
import { useCreateDriverMutation } from '@features/drivers/driversApiSlice'
import {
  useCreateTrailerMutation,
  useCreateTruckMutation,
} from '@features/equipment/equipmentApiSlice'
import { ICreateTrailerRequest, ICreateTruckRequest } from '@pkg/equipment/requests'
import { IDriverCreateRequest } from '@pkg/drivers/requests'
import styles from './loadDialog.module.scss'
import appConfig from '@/config'
import { getRegion } from '@shared/utils/formatters'

import {
  Box,
  Stack,
  Step,
  StepLabel,
  TextField,
  Stepper,
  Tooltip,
  Button,
  MenuItem,
  Checkbox,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { ITheme } from '@/pkg/sokarUI'

const steps = ['add stops & basic info', 'companies & pay', 'driver & equipment']

interface ICreateLoadProps {
  refetch: () => void
  handleClose: () => void
}

const CreateLoadDialog = ({ refetch, handleClose }: ICreateLoadProps): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const country: string | null = localStorage.getItem('country')
  const [createLoad] = useCreateLoadMutation()
  const { dispatch: dispatchAlert } = useAlert()

  const [createDriver] = useCreateDriverMutation()
  const [createTruck] = useCreateTruckMutation()
  const [createTrailer] = useCreateTrailerMutation()
  const { companyId } = useOwnUser()

  const {
    control: trailerControl,
    getValues: getTrailer,
    formState: { errors: trailerErrors },
  } = useForm<ICreateTrailerRequest>()

  const {
    control: truckControl,
    getValues: getTruck,
    formState: { errors: truckErrors },
  } = useForm<ICreateTruckRequest>()

  const {
    control: driverControl,
    getValues: getDriver,
    formState: { errors: driverErrors },
  } = useForm<IDriverCreateRequest>()

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICreateCarrierInstanceLoadRequest>({
    defaultValues: initICreateCarrierLoadRequest(),
    resolver: yupResolver(brokerInstancePublishedLoadValidator),
  })

  const {
    fields: stops,
    append,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'stops',
  })

  const [stopCountryCodes, setCountryCodes] = useState<string[]>(
    stops.map((stop) => stop.countryAbbreviation),
  )

  const [loadCreationStep, setLoadCreationStep] = useState<number>(0)
  const [addDriver, setAddDriver] = useState<boolean>(false)
  const [addTruck, setAddTruck] = useState<boolean>(false)
  const [addTrailer, setAddTrailer] = useState<boolean>(false)
  const [carrierId, setCarrierId] = useState<string>('')

  useEffect(() => {
    setValue('publishedPrice', 69)
  }, [])

  const moveUp = (index: number) => {
    if (index > 0) swap(index, index - 1)
    setCountryCodes((prevCountryCodes) => {
      const newCountryCodes = [...prevCountryCodes]
      ;[newCountryCodes[index], newCountryCodes[index - 1]] = [
        newCountryCodes[index - 1],
        newCountryCodes[index],
      ]

      return newCountryCodes
    })
  }

  const moveDown = (index: number) => {
    if (index < stops.length - 1) swap(index, index + 1)
    setCountryCodes((prevCountryCodes) => {
      const newCountryCodes = [...prevCountryCodes]
      ;[newCountryCodes[index], newCountryCodes[index + 1]] = [
        newCountryCodes[index + 1],
        newCountryCodes[index],
      ]

      return newCountryCodes
    })
  }

  const handleCityChange = (index: number, city: ICity): void => {
    setValue(`stops.${index}.zipCode`, city.postalCode)
    setValue(`stops.${index}.lat`, city.latitude)
    setValue(`stops.${index}.lon`, city.longitude)
    setValue(`stops.${index}.city`, `${city.name}, ${city.state}`)
  }

  const handleCountryChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void,
    name: string,
    index: number,
  ) => {
    onChange(e)
    setValue(eval(name), countryCodes[e.target.value as keyof typeof countryCodes])
    const updatedCodes = [...stopCountryCodes]
    updatedCodes[index] = e.target.value
    setCountryCodes(updatedCodes)
  }

  const handleCarrierChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setCarrierId(e.target.value)
    setValue('carrierId', e.target.value)
  }

  const onSubmit = async (loadData: ICreateCarrierInstanceLoadRequest): Promise<void> => {
    try {
      if (addDriver) {
        const driverData = getDriver()
        driverData.carrierId = loadData.carrierId
        driverData.companyId = loadData.carrierId
        const driverRes = await createDriver(driverData).unwrap()
        loadData.driverIds = [driverRes.id]
      }
      if (addTruck) {
        const truckData = getTruck()
        truckData.carrierId = loadData.carrierId
        truckData.companyId = loadData.carrierId
        const truckRes = await createTruck(truckData).unwrap()
        loadData.truckId = truckRes.id
      }
      if (addTrailer) {
        const trailerData = getTrailer()
        trailerData.carrierId = loadData.carrierId
        trailerData.companyId = loadData.carrierId
        const trailerRes = await createTrailer(trailerData).unwrap()
        loadData.trailerId = trailerRes.id
      }

      loadData.tags = ['ongoing-transport']
      for (const stop of loadData.stops) {
        if (stop.time && stop.date) {
          const time = new Date(stop.time)
          let date = new Date(stop.date)
          const offset = date.getTimezoneOffset()
          date.setHours(time.getHours(), time.getMinutes() - offset, 0)
          stop.date = date.toISOString()
          delete stop.time
        }
        if (stop.timeTo && stop.dateTo) {
          const time = new Date(stop.timeTo)
          let date = new Date(stop.dateTo)
          const offset = date.getTimezoneOffset()
          date.setHours(time.getHours(), time.getMinutes() - offset, 0)
          stop.dateTo = date.toISOString()
          delete stop.timeTo
        }
      }

      await createLoad(loadData).unwrap()
      dispatchAlert({
        type: 'SHOW',
        payload: {
          content: t('Market:response.transportCreated'),
          severity: 'success',
        },
      })
      reset()
      handleClose()
      refetch()
    } catch (err: any) {
      const error = parseError<any>(err.data.message)
      dispatchAlert({
        type: 'SHOW',
        payload: {
          content: String(t(error.dictKey, { ...error.dependencies })),
          severity: 'error',
        },
      })
    }
  }

  const isStepFailed = (
    index: number,
    errors: FieldErrors<ICreateCarrierInstanceLoadRequest>,
  ): boolean => {
    const errorKeys = [
      ['stops', 'weight'],
      ['shipperPrice', 'carrierPrice', 'shipperId', 'carrierId'],
      ['truckId', 'trailerId', 'driverIds'],
    ]
    return errorKeys[index].some((key) => key in errors)
  }
  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={3}
    >
      <Box
        className={styles.stepper}
        sx={{
          backgroundColor: appTheme.palette.background.default,
        }}
      >
        <Stepper activeStep={loadCreationStep}>
          {steps.map((label, index) => {
            let isError: boolean = false
            if (isStepFailed(index, errors)) {
              isError = true
            }
            return (
              <Step
                key={label}
                className={'create_load_dialog_text_step'}
                onClick={() => setLoadCreationStep(index)}
              >
                <StepLabel error={isError} />
              </Step>
            )
          })}
        </Stepper>
      </Box>
      {loadCreationStep === 0 && (
        <>
          {stops.map((field, index: number) => {
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box key={field.id}>
                  <Stack
                    direction={'row'}
                    spacing={2}
                  >
                    <Typography>
                      {t('Fleet:createLoadDialog.stopTitle', { noStop: index + 1 })}
                    </Typography>
                    <span className={styles.textfield} />
                    <Tooltip title={t('Fleet:createLoadDialog.moveDown')}>
                      <IconButton
                        onClick={() => moveDown(index)}
                        disabled={index === stops.length - 1}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Fleet:createLoadDialog.moveUp')}>
                      <IconButton
                        onClick={() => moveUp(index)}
                        disabled={index === 0}
                      >
                        <ExpandLessIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Fleet:createLoadDialog.deleteStop', { noStop: index + 1 })}>
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Stack
                    direction={'column'}
                    spacing={3}
                  >
                    <Stack
                      direction={'row'}
                      spacing={2}
                    >
                      <Controller
                        control={control}
                        name={`stops.${index}.countryAbbreviation`}
                        render={({ field: { name, value, onChange } }) => (
                          <TextField
                            select
                            value={value}
                            onChange={(e) => {
                              handleCountryChange(e, onChange, `stops.${index}.country`, index)
                            }}
                            className={styles.textfield}
                            label={t('Fleet:createLoadDialog.country')}
                            error={!!errors?.stops?.[index]?.countryAbbreviation}
                            helperText={
                              <Box height={2}>
                                {errors?.stops?.[index]?.countryAbbreviation?.message}
                              </Box>
                            }
                          >
                            {Object.keys(countryCodes)
                              // @ts-ignore
                              .filter((code) => countryCodes[code] === getRegion(country ?? ''))
                              .map((country) => (
                                <MenuItem value={country}>
                                  {t('Common:countries.' + country)}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      />
                      <Controller
                        control={control}
                        name={`stops.${index}.city`}
                        render={({ field }) => (
                          <CityField
                            index={index}
                            value={field.value}
                            country={stopCountryCodes[index]}
                            error={errors?.stops?.[index]?.city}
                            handleValueChange={handleCityChange}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction={'row'}
                      spacing={2}
                    >
                      <Controller
                        control={control}
                        name={`stops.${index}.category`}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            select
                            className={styles.textfield}
                            label={t('Fleet:createLoadDialog.category')}
                            error={!!errors?.stops?.[index]?.category}
                            helperText={
                              <Box height={2}>{errors?.stops?.[index]?.category?.message}</Box>
                            }
                          >
                            <MenuItem value={'P'}>{t('Fleet:createLoadDialog.P')}</MenuItem>
                            <MenuItem value={'R'}>{t('Fleet:createLoadDialog.R')}</MenuItem>
                            <MenuItem value={'D'}>{t('Fleet:createLoadDialog.D')}</MenuItem>
                          </TextField>
                        )}
                      />
                      <Controller
                        control={control}
                        name={`stops.${index}.street`}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className={styles.textfield}
                            label={t('Fleet:createLoadDialog.address')}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction={'row'}
                      spacing={2}
                    >
                      <Controller
                        control={control}
                        name={`stops.${index}.date`}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              format={appConfig.DATE_FORMAT}
                              label={t('Fleet:createLoadDialog.date')}
                              value={dayjs(field.value)}
                              onChange={field.onChange}
                              className={styles.textfield}
                              slotProps={{
                                textField: {
                                  error: !!errors?.stops?.[index]?.category,
                                  helperText: (
                                    <Box height={2}>
                                      {errors?.stops?.[index]?.category?.message}
                                    </Box>
                                  ),
                                },
                              }}
                            />
                            <Stack
                              direction={'column'}
                              className={styles.dateModifier}
                            >
                              <Tooltip title={t('Market:offerDialog.addDay')}>
                                <IconButton
                                  onClick={() => field.onChange(dayjs(field.value).add(1, 'day'))}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t('Market:offerDialog.subtractDay')}>
                                <IconButton
                                  onClick={() =>
                                    field.onChange(dayjs(field.value).subtract(1, 'day'))
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </>
                        )}
                      />
                      <Controller
                        control={control}
                        name={`stops.${index}.dateTo`}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              format={appConfig.DATE_FORMAT}
                              label={t('Fleet:createLoadDialog.dateTo')}
                              value={dayjs(field.value)}
                              onChange={field.onChange}
                              className={styles.textfield}
                              slotProps={{
                                textField: {
                                  error: !!errors?.stops?.[index]?.dateTo,
                                  helperText: (
                                    <Box height={2}>{errors?.stops?.[index]?.dateTo?.message}</Box>
                                  ),
                                },
                              }}
                            />
                            <Stack
                              direction={'column'}
                              className={styles.dateModifier}
                            >
                              <Tooltip title={t('Market:offerDialog.addDay')}>
                                <IconButton
                                  onClick={() => field.onChange(dayjs(field.value).add(1, 'day'))}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t('Market:offerDialog.subtractDay')}>
                                <IconButton
                                  onClick={() =>
                                    field.onChange(dayjs(field.value).subtract(1, 'day'))
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </>
                        )}
                      />
                    </Stack>
                    <Stack
                      direction={'row'}
                      spacing={2}
                    >
                      <Controller
                        control={control}
                        name={`stops.${index}.time`}
                        render={({ field }) => (
                          <TimeField
                            ampm={false}
                            label={t('Fleet:createLoadDialog.time')}
                            value={dayjs(field.value)}
                            onChange={field.onChange}
                            format={'HH:mm'}
                            className={styles.textfield}
                            slotProps={{
                              textField: {
                                error: !!errors?.stops?.[index]?.time,
                                helperText: (
                                  <Box height={2}>{errors?.stops?.[index]?.time?.message}</Box>
                                ),
                              },
                            }}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`stops.${index}.timeTo`}
                        render={({ field }) => (
                          <TimeField
                            ampm={false}
                            label={t('Fleet:createLoadDialog.timeTo')}
                            value={dayjs(field.value)}
                            onChange={field.onChange}
                            format={'HH:mm'}
                            className={styles.textfield}
                            slotProps={{
                              textField: {
                                error: !!errors?.stops?.[index]?.timeTo,
                                helperText: (
                                  <Box height={2}>{errors?.stops?.[index]?.timeTo?.message}</Box>
                                ),
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </Box>
              </LocalizationProvider>
            )
          })}
          <Button
            variant={'contained'}
            onClick={() => {
              append(initICreateStopRequest())
              setCountryCodes((prev) => [...prev, country ?? 'US'])
            }}
          >
            {t('Fleet:createLoadDialog.addStop')}
          </Button>
          <Typography>{t('Fleet:createLoadDialog.details')}</Typography>
          <Controller
            control={control}
            name={'type'}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t('Fleet:createLoadDialog.type')}
                error={!!errors?.type}
                helperText={<Box height={2}>{errors?.type?.message}</Box>}
              >
                <MenuItem value={'PTL'}>{t('Fleet:createLoadDialog.type_PTL')}</MenuItem>
                <MenuItem value={'LTL'}>{t('Fleet:createLoadDialog.type_LTL')}</MenuItem>
                <MenuItem value={'FTL'}>{t('Fleet:createLoadDialog.type_FTL')}</MenuItem>
              </TextField>
            )}
          />
          <Controller
            control={control}
            name={'commodityName'}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('Fleet:createLoadDialog.commodityName')}
                error={!!errors?.commodityName}
                helperText={<Box height={2}>{errors?.commodityName?.message}</Box>}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Controller
              control={control}
              name={'weight'}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={'number'}
                  className={styles.textfield}
                  label={t('Fleet:createLoadDialog.weight')}
                  error={!!errors?.weight}
                  helperText={<Box height={2}>{errors?.weight?.message}</Box>}
                />
              )}
            />
            <Controller
              control={control}
              name={'weightUnit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  className={styles.textfield}
                  label={t('Fleet:createLoadDialog.weightUnit')}
                  error={!!errors?.weightUnit}
                  helperText={<Box height={2}>{errors?.weightUnit?.message}</Box>}
                >
                  <MenuItem value={'lbs'}>{t('Fleet:createLoadDialog.weightUnit_lbs')}</MenuItem>
                  <MenuItem value={'kg'}>{t('Fleet:createLoadDialog.weightUnit_kg')}</MenuItem>
                  <MenuItem value={'t'}>{t('Fleet:createLoadDialog.weightUnit_t')}</MenuItem>
                </TextField>
              )}
            />
          </Stack>
          <Controller
            control={control}
            name={'description'}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={3}
                label={t('Fleet:createLoadDialog.description')}
                error={!!errors?.description}
                helperText={<Box height={2}>{errors?.description?.message}</Box>}
              />
            )}
          />
          <Button
            variant={'contained'}
            type={'submit'}
            onClick={() => setLoadCreationStep((prev) => (prev += 1))}
          >
            {t('Common:next')}
          </Button>
        </>
      )}
      {loadCreationStep === 1 && (
        <>
          <Typography>{t('Market:offerDialog.shipper')}</Typography>
          <Controller
            control={control}
            name={'shipperId'}
            render={({ field }) => (
              <CompanySearch
                {...field}
                error={errors?.shipperId}
                isDisabled={false}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Controller
              control={control}
              name={'shipperPrice'}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={'number'}
                  className={styles.textfield}
                  label={t('Market:offerDialog.shipperPrice')}
                  error={!!errors?.shipperPrice}
                  helperText={<Box height={2}>{errors?.shipperPrice?.message}</Box>}
                />
              )}
            />
            <Controller
              control={control}
              name={'shipperPriceCurrency'}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  className={styles.textfield}
                  label={t('Market:offerDialog.shipperPriceCurrency')}
                  error={!!errors?.shipperPriceCurrency}
                  helperText={<Box height={2}>{errors?.shipperPriceCurrency?.message}</Box>}
                >
                  {/* @ts-ignore */}
                  {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                    (currency: string) => (
                      <MenuItem value={currency}>{t('Common:currencies.' + currency)}</MenuItem>
                    ),
                  )}
                </TextField>
              )}
            />
          </Stack>
          <Typography>{t('Fleet:createLoadDialog.carrier')}</Typography>
          <Controller
            control={control}
            name={'carrierId'}
            render={({ field }) => (
              <CompanySearch
                {...field}
                value={field.value}
                onChange={handleCarrierChange}
                error={errors?.carrierId}
                isDisabled={false}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Controller
              control={control}
              name={'carrierPrice'}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={'number'}
                  className={styles.textfield}
                  label={t('Market:offerDialog.carrierPrice')}
                  error={!!errors?.shipperPrice}
                  helperText={<Box height={2}>{errors?.shipperPrice?.message}</Box>}
                />
              )}
            />
            <Controller
              control={control}
              name={'carrierPriceCurrency'}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  className={styles.textfield}
                  label={t('Market:offerDialog.carrierPriceCurrency')}
                  error={!!errors?.shipperPriceCurrency}
                  helperText={<Box height={2}>{errors?.shipperPriceCurrency?.message}</Box>}
                >
                  {/* @ts-ignore */}
                  {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                    (currency: string) => (
                      <MenuItem value={currency}>{t('Common:currencies.' + currency)}</MenuItem>
                    ),
                  )}
                </TextField>
              )}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Button
              variant={'contained'}
              type={'submit'}
              className={styles.textfield}
              onClick={() => setLoadCreationStep((prev) => (prev -= 1))}
            >
              {t('Common:previous')}
            </Button>
            <Button
              variant={'contained'}
              type={'submit'}
              className={styles.textfield}
              onClick={() => setLoadCreationStep((prev) => (prev += 1))}
            >
              {t('Common:next')}
            </Button>
          </Stack>
        </>
      )}
      {loadCreationStep === 2 && (
        <>
          <Controller
            control={control}
            name={'driverIds'}
            render={({ field }) => (
              <MultipleDriversSearch
                {...field}
                error={errors?.driverIds}
                isDisabled={!carrierId || addDriver}
                companyId={carrierId}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
            className={styles.addModelStack}
          >
            <Checkbox
              onClick={() => setAddDriver((prev) => !prev)}
              disabled={!carrierId || carrierId === companyId}
            />
            <Typography>{t('Market:offerDialog.addDriver')}</Typography>
          </Stack>
          {addDriver && (
            <>
              <Controller
                control={driverControl}
                name={'firstName'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.name')}
                    error={!!driverErrors?.firstName}
                    helperText={<Box height={2}>{driverErrors?.firstName?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={driverControl}
                name={'lastName'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.surname')}
                    error={!!driverErrors?.lastName}
                    helperText={<Box height={2}>{driverErrors?.lastName?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={driverControl}
                name={'phone'}
                render={({ field }) => (
                  <PhoneNumberField
                    {...field}
                    error={driverErrors.phone}
                  />
                )}
              />
              <Controller
                control={driverControl}
                name={'socialSecurity'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.socialSecurity')}
                    error={!!driverErrors?.socialSecurity}
                    helperText={<Box height={2}>{driverErrors?.socialSecurity?.message}</Box>}
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name={'truckId'}
            render={({ field }) => (
              <TruckSearch
                {...field}
                error={errors?.truckId}
                isDisabled={!carrierId}
                companyId={carrierId}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
            className={styles.addModelStack}
          >
            <Checkbox
              onClick={() => setAddTruck((prev) => !prev)}
              disabled={!carrierId || carrierId === companyId}
            />
            <Typography>{t('Market:offerDialog.addTruck')}</Typography>
          </Stack>
          {addTruck && (
            <>
              <Controller
                control={truckControl}
                name={'plate'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.plates')}
                    error={!!truckErrors?.plate}
                    helperText={<Box height={2}>{truckErrors?.plate?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={truckControl}
                name={'number'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.number')}
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name={'trailerId'}
            render={({ field }) => (
              <TrailerSearch
                {...field}
                error={errors?.trailerId}
                isDisabled={!carrierId}
                companyId={carrierId}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
            className={styles.addModelStack}
          >
            <Checkbox
              onClick={() => setAddTrailer((prev) => !prev)}
              disabled={!carrierId || carrierId === companyId}
            />
            <Typography>{t('Market:offerDialog.addTrailer')}</Typography>
          </Stack>
          {addTrailer && (
            <>
              <Controller
                control={trailerControl}
                name={'plate'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.plates')}
                    error={!!trailerErrors?.plate}
                    helperText={<Box height={2}>{trailerErrors?.plate?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={trailerControl}
                name={'year'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={'number'}
                    label={t('Market:offerDialog.year')}
                    error={!!trailerErrors?.year}
                    helperText={<Box height={2}>{trailerErrors?.year?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={trailerControl}
                name={'number'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.number')}
                  />
                )}
              />
            </>
          )}
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Button
              variant={'contained'}
              type={'submit'}
              className={styles.textfield}
              onClick={() => setLoadCreationStep((prev) => (prev -= 1))}
            >
              {t('Common:previous')}
            </Button>
            <Button
              variant={'contained'}
              type={'submit'}
              className={styles.textfield}
            >
              {t('Common:create')}
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default CreateLoadDialog
