import dayjs from 'dayjs'
import { t } from 'i18next'

const country: string | null = localStorage.getItem('country')

const today = dayjs().startOf('day')
const tomorrow = dayjs().startOf('day').add(1, 'day')

export const usMyMarketOffersStopDefaults: Partial<any> = {
  city: '',
  date: today,
  dateTo: today,
  time: today.hour(7).minute(0),
  timeTo: today.hour(15).minute(0),
  countryAbbreviation: country?.toUpperCase() ?? 'US',
  country: t(`Common:countries.${country?.toUpperCase()}` ?? 'US'),
}

export const usMyMarketOffersDefaults: Partial<any> = {
  type: 'FTL',
  publishedPriceCurrency: 'USD',
  weightUnit: 'lbs',
  stops: [
    {
      city: '',
      category: 'P',
      date: today,
      dateTo: today,
      time: today.hour(7).minute(0),
      timeTo: today.hour(15).minute(0),
      countryAbbreviation: country?.toUpperCase() ?? 'US',
      country: t(`Common:countries.${country?.toUpperCase()}` ?? 'US'),
    },
    {
      city: '',
      category: 'D',
      date: tomorrow,
      dateTo: tomorrow,
      time: today.hour(7).minute(0),
      timeTo: today.hour(15).minute(0),
      countryAbbreviation: country?.toUpperCase() ?? 'US',
      country: t(`Common:countries.${country?.toUpperCase()}` ?? 'US'),
    },
  ],
}
