/**
 * Throws an error if the condition is false
 *
 * @param {boolean} condition - The condition to check. If it's false, the assertion will fail
 * @param {string} [message] - An optional error message to display if the assertion fails. Defaults to 'Assertion failed' if not provided
 *
 * @throws {Error} Throws an error with the provided message if the condition is false
 *
 * @example
 * assert(value !== null, 'Value must not be null')
 * console.log(value.toUpperCase()) // Safe, because the assert ensures `value` is not null
 */
export function assert(condition: boolean, message?: string): asserts condition {
  // NOTE: in TypeScript asserts cannot use arrow functions
  if (!condition) {
    throw new Error(message ?? 'Assertion failed') // TODO: disable this fucking eslint here
  }
}
