import { useState, ChangeEvent } from 'react'
import { t } from 'i18next'
import styles from './loadDialog.module.scss'
import { useForm, Controller, useFieldArray, FieldErrors } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ICity } from '@pkg/loads/models'
import { countryCodes } from '@pkg/countryCodes'
import { initICreateCarrierLoadRequest, initICreateStopRequest } from '@pkg/loads/initializers'
import { ICreateCarrierInstanceLoadRequest } from '@pkg/loads/requests'
import { carrierInstanceLoadValidator } from '@pkg/loads/validators'
import { parseError, getRegion } from '@shared/utils/formatters'
import { useCreateLoadMutation } from '@features/loads/loadsApiSlice'
import useAlert from '@hooks/useAlert'
import useOwnUser from '@hooks/useOwnUser'
import appConfig from '@/config'
import DateField from '@lib/DateField'
import CityField from '@lib/CityField'
import CompanySearch from '@lib/CompanySearch'
import TruckSearch from '@shared/components/truckSearch'
import TrailerSearch from '@shared/components/trailerSearch'
import MultipleDriversSearch from '@shared/components/multipleDriversSearch'

import {
  Box,
  Stack,
  Step,
  StepLabel,
  TextField,
  Stepper,
  Tooltip,
  Button,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const steps = ['add stops & basic info', 'companies & pay', 'driver & equipment']

interface ICreateLoadProps {
  refetch: () => void
  handleClose: () => void
}

const CreateLoadDialog = ({ ...props }: ICreateLoadProps): JSX.Element => {
  const country: string | null = localStorage.getItem('country')
  const [createLoad] = useCreateLoadMutation()
  const { dispatch: dispatchAlert } = useAlert()

  const isStepFailed = (
    index: number,
    errors: FieldErrors<ICreateCarrierInstanceLoadRequest>,
  ): boolean => {
    const errorKeys0: string[] = ['stops', 'externalId', 'weight']
    const errorKeys1: string[] = ['shipperPrice', 'shipperId', 'carrierId']
    const errorKeys2: string[] = ['truckId', 'trailerId', 'driverIds']

    switch (index) {
      case 0:
        return errorKeys0.some((key) => key in errors)
      case 1:
        return errorKeys1.some((key) => key in errors)
      case 2:
        return errorKeys2.some((key) => key in errors)
      default:
        return false
    }
  }

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<ICreateCarrierInstanceLoadRequest>({
    defaultValues: initICreateCarrierLoadRequest(),
    resolver: yupResolver(carrierInstanceLoadValidator),
    mode: 'onSubmit',
  })

  const {
    fields: stops,
    append,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'stops',
  })
  const [stopCountryCodes, setStopCountryCodes] = useState<string[]>(
    stops.map((stop) => stop.countryAbbreviation),
  )

  const moveUp = (index: number) => {
    if (index > 0) swap(index, index - 1)
  }

  const moveDown = (index: number) => {
    if (index < stops.length - 1) swap(index, index + 1)
  }

  const handleCityChange = (index: number, city: ICity): void => {
    setValue(`stops.${index}.zipCode`, city.postalCode)
    setValue(`stops.${index}.lat`, city.latitude)
    setValue(`stops.${index}.lon`, city.longitude)
    setValue(`stops.${index}.city`, `${city.name}, ${city.state}`)
    clearErrors(`stops.${index}.city`)
  }

  const onSubmit = (loadData: ICreateCarrierInstanceLoadRequest): void => {
    loadData.carrierPrice = loadData.shipperPrice
    loadData.carrierPriceCurrency = loadData.shipperPriceCurrency
    loadData.publishedPriceCurrency = loadData.shipperPriceCurrency
    loadData.tags = ['carrier-load']
    for (const stop of loadData.stops) {
      if (stop.time && stop.date) {
        const time = new Date(stop.time)
        let date = new Date(stop.date)
        const offset = date.getTimezoneOffset()
        date.setHours(time.getHours(), time.getMinutes() - offset, 0)
        stop.date = date.toISOString()
        delete stop.time
      }
      if (stop.timeTo && stop.dateTo) {
        const time = new Date(stop.timeTo)
        let date = new Date(stop.dateTo)
        const offset = date.getTimezoneOffset()
        date.setHours(time.getHours(), time.getMinutes() - offset, 0)
        stop.dateTo = date.toISOString()
        delete stop.timeTo
      }
    }
    createLoad(loadData)
      .unwrap()
      .then(() => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Fleet:responses.loadCreated'),
            severity: 'success',
          },
        })
      })
      .then(() => reset())
      .then(() => props.handleClose())
      .then(() => props.refetch())
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
  }

  const handleCountryChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void,
    name: string,
    index: number,
  ) => {
    onChange(e)
    setValue(
      name as keyof ICreateCarrierInstanceLoadRequest,
      countryCodes[e.target.value as keyof typeof countryCodes],
    )
    const updatedCodes = [...stopCountryCodes]
    updatedCodes[index] = e.target.value
    setStopCountryCodes(updatedCodes)
  }

  const { companyId: ownCompanyId } = useOwnUser()
  const [carrierId, setCarrierId] = useState<string>('')
  const handleCarrierChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setCarrierId(e.target.value)
    setValue('carrierId', e.target.value)
    clearErrors('carrierId')
  }

  const [loadCreationStep, setLoadCreationStep] = useState<number>(0)

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={3}
    >
      <Box>
        <Stepper activeStep={loadCreationStep}>
          {steps.map((label, index) => {
            let isError: boolean = false
            if (isStepFailed(index, errors)) {
              isError = true
            }
            return (
              <Step
                key={label}
                className={styles.textStep}
                onClick={() => setLoadCreationStep(index)}
              >
                <StepLabel error={isError} />
              </Step>
            )
          })}
        </Stepper>
      </Box>
      <Stack
        sx={{ display: loadCreationStep == 0 ? 'flex' : 'none' }}
        spacing={2}
      >
        {stops.map((field, index: number) => {
          return (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              key={field.id}
            >
              <Box key={field.id}>
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Typography>
                    {t('Fleet:createLoadDialog.stopTitle', { noStop: index + 1 })}
                  </Typography>
                  <span className={styles.textField} />
                  <Tooltip title={t('Fleet:createLoadDialog.moveDown')}>
                    <IconButton
                      onClick={() => moveDown(index)}
                      disabled={index === stops.length - 1}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Fleet:createLoadDialog.moveUp')}>
                    <IconButton
                      onClick={() => moveUp(index)}
                      disabled={index === 0}
                    >
                      <ExpandLessIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Fleet:createLoadDialog.deleteStop', { noStop: index + 1 })}>
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Stack
                  direction={'column'}
                  spacing={3}
                >
                  <Stack
                    direction={'row'}
                    spacing={2}
                  >
                    <Controller
                      control={control}
                      name={`stops.${index}.countryAbbreviation`}
                      render={({ field: { name, value, onChange } }) => (
                        <TextField
                          select
                          value={value}
                          onChange={(e) => {
                            handleCountryChange(e, onChange, `stops.${index}.country`, index)
                          }}
                          className={styles.textField}
                          label={t('Fleet:createLoadDialog.country')}
                          error={!!errors?.stops?.[index]?.countryAbbreviation}
                          helperText={
                            <Box height={2}>
                              {errors?.stops?.[index]?.countryAbbreviation?.message}
                            </Box>
                          }
                        >
                          {Object.keys(countryCodes)
                            .filter(
                              (code) =>
                                countryCodes[code as keyof typeof countryCodes] ===
                                getRegion(country ?? ''),
                            )
                            .map((country) => (
                              <MenuItem
                                value={country}
                                key={country}
                              >
                                {t('Common:countries.' + country)}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    />
                    <Controller
                      control={control}
                      name={`stops.${index}.city`}
                      render={({ field }) => (
                        <CityField
                          index={index}
                          value={field.value}
                          country={stopCountryCodes[index]}
                          error={errors?.stops?.[index]?.city}
                          handleValueChange={handleCityChange}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction={'row'}
                    spacing={2}
                  >
                    <Controller
                      control={control}
                      name={`stops.${index}.category`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          className={styles.textField}
                          label={t('Fleet:createLoadDialog.category')}
                          error={!!errors?.stops?.[index]?.category}
                          helperText={
                            <Box height={2}>{errors?.stops?.[index]?.category?.message}</Box>
                          }
                        >
                          <MenuItem value={'P'}>{t('Fleet:createLoadDialog.P')}</MenuItem>
                          <MenuItem value={'R'}>{t('Fleet:createLoadDialog.R')}</MenuItem>
                          <MenuItem value={'D'}>{t('Fleet:createLoadDialog.D')}</MenuItem>
                        </TextField>
                      )}
                    />
                    <Controller
                      control={control}
                      name={`stops.${index}.street`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className={styles.textField}
                          label={t('Fleet:createLoadDialog.address')}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction={'row'}
                    spacing={2}
                  >
                    <Controller
                      control={control}
                      name={`stops.${index}.date`}
                      render={({ field }) => (
                        <DateField
                          {...field}
                          error={errors?.stops?.[index]?.date}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`stops.${index}.dateTo`}
                      render={({ field }) => (
                        <DateField
                          {...field}
                          error={errors?.stops?.[index]?.dateTo}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction={'row'}
                    spacing={2}
                  >
                    <Controller
                      control={control}
                      name={`stops.${index}.time`}
                      render={({ field }) => (
                        <TimeField
                          ampm={false}
                          label={t('Fleet:createLoadDialog.time')}
                          {...field}
                          format={'HH:mm'}
                          className={styles.textField}
                          slotProps={{
                            textField: {
                              error: !!errors?.stops?.[index]?.time,
                              helperText: (
                                <Box height={2}>{errors?.stops?.[index]?.time?.message}</Box>
                              ),
                            },
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`stops.${index}.timeTo`}
                      render={({ field }) => (
                        <TimeField
                          ampm={false}
                          label={t('Fleet:createLoadDialog.timeTo')}
                          {...field}
                          format={'HH:mm'}
                          className={styles.textField}
                          slotProps={{
                            textField: {
                              error: !!errors?.stops?.[index]?.timeTo,
                              helperText: (
                                <Box height={2}>{errors?.stops?.[index]?.timeTo?.message}</Box>
                              ),
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Box>
            </LocalizationProvider>
          )
        })}
        <Button
          variant={'contained'}
          onClick={() => {
            append(initICreateStopRequest())
            setStopCountryCodes((prev) => [...prev, country ?? 'US'])
          }}
        >
          {t('Fleet:createLoadDialog.addStop')}
        </Button>
        <Typography>{t('Fleet:createLoadDialog.details')}</Typography>
        <Controller
          control={control}
          name={'externalId'}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Fleet:createLoadDialog.externalId')}
              error={!!errors?.externalId}
              helperText={<Box height={2}>{errors?.externalId?.message}</Box>}
            />
          )}
        />
        <Controller
          control={control}
          name={'type'}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t('Fleet:createLoadDialog.type')}
              error={!!errors?.type}
              helperText={<Box height={2}>{errors?.type?.message}</Box>}
            >
              <MenuItem value={'PTL'}>{t('Fleet:createLoadDialog.type_PTL')}</MenuItem>
              <MenuItem value={'LTL'}>{t('Fleet:createLoadDialog.type_LTL')}</MenuItem>
              <MenuItem value={'FTL'}>{t('Fleet:createLoadDialog.type_FTL')}</MenuItem>
            </TextField>
          )}
        />
        <Controller
          control={control}
          name={'commodityName'}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Fleet:createLoadDialog.commodityName')}
              error={!!errors?.commodityName}
              helperText={<Box height={2}>{errors?.commodityName?.message}</Box>}
            />
          )}
        />
        <Stack
          direction={'row'}
          spacing={2}
        >
          <Controller
            control={control}
            name={'weight'}
            render={({ field }) => (
              <TextField
                {...field}
                type={'number'}
                className={styles.textField}
                label={t('Fleet:createLoadDialog.weight')}
                error={!!errors?.weight}
                helperText={<Box height={2}>{errors?.weight?.message}</Box>}
              />
            )}
          />
          <Controller
            control={control}
            name={'weightUnit'}
            render={({ field }) => (
              <TextField
                {...field}
                select
                className={styles.textField}
                label={t('Fleet:createLoadDialog.weightUnit')}
                error={!!errors?.weightUnit}
                helperText={<Box height={2}>{errors?.weightUnit?.message}</Box>}
              >
                <MenuItem value={'lbs'}>{t('Fleet:createLoadDialog.weightUnit_lbs')}</MenuItem>
                <MenuItem value={'kg'}>{t('Fleet:createLoadDialog.weightUnit_kg')}</MenuItem>
                <MenuItem value={'t'}>{t('Fleet:createLoadDialog.weightUnit_t')}</MenuItem>
              </TextField>
            )}
          />
        </Stack>
        <Controller
          control={control}
          name={'loadingMeters'}
          render={({ field }) => (
            <TextField
              {...field}
              type={'number'}
              className={styles.textField}
              label={t('Fleet:createLoadDialog.loadingMeters')}
              error={!!errors?.loadingMeters}
              helperText={<Box height={2}>{errors?.loadingMeters?.message}</Box>}
            />
          )}
        />
        <Controller
          control={control}
          name={'description'}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={3}
              label={t('Fleet:createLoadDialog.description')}
              error={!!errors?.description}
              helperText={<Box height={2}>{errors?.description?.message}</Box>}
            />
          )}
        />
        <Button
          variant={'contained'}
          onClick={() => setLoadCreationStep((prev) => (prev += 1))}
        >
          {t('Common:next')}
        </Button>
      </Stack>
      <Stack
        sx={{ display: loadCreationStep === 1 ? 'flex' : 'none' }}
        spacing={2}
      >
        <Typography>{t('Fleet:createLoadDialog.broker')}</Typography>
        <Controller
          control={control}
          name={'shipperId'}
          render={({ field }) => (
            <CompanySearch
              {...field}
              error={errors?.shipperId}
              isDisabled={false}
            />
          )}
        />
        <Stack
          direction={'row'}
          spacing={2}
        >
          <Controller
            control={control}
            name={'shipperPrice'}
            render={({ field }) => (
              <TextField
                {...field}
                type={'number'}
                className={styles.textField}
                label={t('Fleet:createLoadDialog.shipperPrice')}
                error={!!errors?.shipperPrice}
                helperText={<Box height={2}>{errors?.shipperPrice?.message}</Box>}
              />
            )}
          />
          <Controller
            control={control}
            name={'shipperPriceCurrency'}
            render={({ field }) => (
              <TextField
                {...field}
                select
                className={styles.textField}
                label={t('Fleet:createLoadDialog.shipperPriceCurrency')}
                error={!!errors?.shipperPriceCurrency}
                helperText={<Box height={2}>{errors?.shipperPriceCurrency?.message}</Box>}
              >
                {/* @ts-ignore */}
                {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                  (currency: string) => (
                    <MenuItem
                      value={currency}
                      key={currency}
                    >
                      {t('Common:currencies.' + currency)}
                    </MenuItem>
                  ),
                )}
              </TextField>
            )}
          />
        </Stack>
        <Typography>{t('Fleet:createLoadDialog.carrier')}</Typography>
        <Controller
          control={control}
          name={'carrierId'}
          render={({ field }) => (
            <CompanySearch
              {...field}
              onChange={handleCarrierChange}
              error={errors?.carrierId}
              isDisabled={false}
            />
          )}
        />
        <Stack
          direction={'row'}
          spacing={2}
        >
          <Button
            variant={'contained'}
            className={styles.textField}
            onClick={() => setLoadCreationStep((prev) => (prev -= 1))}
          >
            {t('Common:previous')}
          </Button>
          <Button
            variant={'contained'}
            className={styles.textField}
            onClick={() => setLoadCreationStep((prev) => (prev += 1))}
          >
            {t('Common:next')}
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{ display: loadCreationStep === 2 ? 'flex' : 'none' }}
        spacing={2}
      >
        <Controller
          control={control}
          name={'driverIds'}
          render={({ field }) => (
            <MultipleDriversSearch
              {...field}
              error={errors?.driverIds}
              isDisabled={!ownCompanyId}
              companyId={carrierId}
            />
          )}
        />
        <Controller
          control={control}
          name={'truckId'}
          render={({ field }) => (
            <TruckSearch
              {...field}
              error={errors?.truckId}
              isDisabled={!ownCompanyId}
              companyId={carrierId}
            />
          )}
        />
        <Controller
          control={control}
          name={'trailerId'}
          render={({ field }) => (
            <TrailerSearch
              {...field}
              error={errors?.trailerId}
              isDisabled={!ownCompanyId}
              companyId={carrierId}
            />
          )}
        />
        <Stack
          direction={'row'}
          spacing={2}
        >
          <Button
            variant={'contained'}
            className={styles.textField}
            onClick={() => setLoadCreationStep((prev) => (prev -= 1))}
          >
            {t('Common:previous')}
          </Button>
          <Button
            variant={'contained'}
            type={'submit'}
            className={styles.textField}
          >
            {t('Common:create')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CreateLoadDialog
