import { useState, ChangeEvent } from 'react'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import useAlert from '@hooks/useAlert'
import { useCreateLoadMutation } from '@features/loads/loadsApiSlice'
import { parseError, getRegion } from '@shared/utils/formatters'
import { ICity } from '@pkg/loads/models'
import { countryCodes } from '@pkg/countryCodes'
import CityField from '@lib/CityField'
import styles from '../offerDialog/offerDialog.module.scss'
import appConfig from '@/config'
import { ICreateStop } from '@pkg/market/models'

import {
  Box,
  Stack,
  TextField,
  Tooltip,
  Button,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TrailerSelect from '@lib/TrailerSelect'
import IntegrationsField from '@lib/IntegrationsField'
import { getMyMarketOffersValidator } from '@pkg/market/validators/getters'
import { getMyMarketOffersDefaults, getMyMarketOffersStopDefaults } from '@pkg/market/defaults'
import DateField from '@/lib/DateField'
import { ICreateMarketOffer } from '@/pkg/market/models'
import { parseCreateMyMarketOffer } from '@/pkg/market/parsers'

const CreateOfferDialog = ({ ...props }): JSX.Element => {
  const country: string | null = localStorage.getItem('country')
  const [createOffer] = useCreateLoadMutation()
  const { dispatch: dispatchAlert } = useAlert()

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<ICreateMarketOffer>({
    defaultValues: getMyMarketOffersDefaults(appConfig.REGION),
    resolver: yupResolver(getMyMarketOffersValidator(appConfig.REGION)),
  })

  const {
    fields: stops,
    append,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'stops',
  })
  const [stopCountryCodes, setCountryCodes] = useState<string[]>(
    stops.map((stop: ICreateStop) => stop.countryAbbreviation),
  )

  const moveUp = (index: number) => {
    if (index > 0) swap(index, index - 1)
    setCountryCodes((prevCountryCodes) => {
      const newCountryCodes = [...prevCountryCodes]
      ;[newCountryCodes[index], newCountryCodes[index - 1]] = [
        newCountryCodes[index - 1],
        newCountryCodes[index],
      ]

      return newCountryCodes
    })
  }

  const moveDown = (index: number) => {
    if (index < stops.length - 1) swap(index, index + 1)
    setCountryCodes((prevCountryCodes) => {
      const newCountryCodes = [...prevCountryCodes]
      ;[newCountryCodes[index], newCountryCodes[index + 1]] = [
        newCountryCodes[index + 1],
        newCountryCodes[index],
      ]

      return newCountryCodes
    })
  }

  const handleCityChange = (index: number, city: ICity): void => {
    setValue(`stops.${index}.zipCode`, city.postalCode)
    setValue(`stops.${index}.lat`, city.latitude)
    setValue(`stops.${index}.lon`, city.longitude)
    setValue(`stops.${index}.city`, `${city.name}, ${city.state}`)
    clearErrors(`stops.${index}.city`)
  }

  const onSubmit = (data: ICreateMarketOffer): void => {
    const request = parseCreateMyMarketOffer(appConfig.REGION, data)

    createOffer(request)
      .unwrap()
      .then(() => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Fleet:responses.loadCreated'),
            severity: 'success',
          },
        })
      })
      .then(() => reset())
      .then(() => props.handleClose())
      .then(() => props.refetch())
      .catch((err) => {
        const error = parseError<any>(err.data.message)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
  }

  const handleCountryChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void,
    name: string,
    index: number,
  ) => {
    onChange(e)
    setValue(name as keyof ICreateMarketOffer, String(t(e.target.value)))
    const updatedCodes = [...stopCountryCodes]
    updatedCodes[index] = e.target.value
    setCountryCodes(updatedCodes)
  }

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={2}
    >
      {stops.map((field, index: number) => {
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            key={field.id}
          >
            <Box
              key={field.id}
              className='columnBox'
            >
              <Stack
                direction={'row'}
                spacing={2}
              >
                <Typography>{t('Market:offerDialog.stopTitle', { noStop: index + 1 })}</Typography>
                <span className={styles.textField} />
                <Tooltip title={t('Market:offerDialog.moveDown')}>
                  <IconButton
                    onClick={() => moveDown(index)}
                    disabled={index === stops.length - 1}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Fleet:offerLoadDialog.moveUp')}>
                  <IconButton
                    onClick={() => moveUp(index)}
                    disabled={index === 0}
                  >
                    <ExpandLessIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Market:offerDialog.deleteStop', { noStop: index + 1 })}>
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack
                direction={'column'}
                spacing={4}
              >
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Controller
                    control={control}
                    name={`stops.${index}.countryAbbreviation`}
                    render={({ field: { name: _name, value, onChange } }) => (
                      <TextField
                        select
                        value={value}
                        onChange={(e) => {
                          handleCountryChange(e, onChange, `stops.${index}.country`, index)
                        }}
                        className={styles.textField}
                        label={t('Market:offerDialog.country')}
                        error={!!errors?.stops?.[index]?.countryAbbreviation}
                        helperText={
                          <Box
                            className={styles.errorBox}
                            height={2}
                          >
                            {errors?.stops?.[index]?.countryAbbreviation?.message}
                          </Box>
                        }
                      >
                        {Object.keys(countryCodes)
                          // @ts-ignore
                          .filter((code) => countryCodes[code] === getRegion(country ?? ''))
                          .map((country) => (
                            <MenuItem value={country}>{t(`Common:countries.${country}`)}</MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`stops.${index}.city`}
                    render={({ field }) => (
                      <CityField
                        index={index}
                        value={field.value}
                        country={stopCountryCodes[index]}
                        error={errors?.stops?.[index]?.city}
                        handleValueChange={handleCityChange}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Controller
                    control={control}
                    name={`stops.${index}.category`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        className={styles.textField}
                        label={t('Market:offerDialog.category')}
                        error={!!errors?.stops?.[index]?.category}
                        helperText={
                          <Box
                            className={styles.errorBox}
                            height={2}
                          >
                            {errors?.stops?.[index]?.category?.message}
                          </Box>
                        }
                      >
                        <MenuItem value={'P'}>{t('Market:offerDialog.P')}</MenuItem>
                        <MenuItem value={'R'}>{t('Market:offerDialog.R')}</MenuItem>
                        <MenuItem value={'D'}>{t('Market:offerDialog.D')}</MenuItem>
                      </TextField>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`stops.${index}.street`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={styles.textField}
                        label={t('Market:offerDialog.address')}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Controller
                    control={control}
                    name={`stops.${index}.date`}
                    render={({ field }) => (
                      <DateField
                        {...field}
                        error={errors?.stops?.[index]?.date}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`stops.${index}.dateTo`}
                    render={({ field }) => (
                      <DateField
                        {...field}
                        error={errors?.stops?.[index]?.dateTo}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Controller
                    control={control}
                    name={`stops.${index}.time`}
                    render={({ field }) => (
                      <TimeField
                        ampm={false}
                        label={t('Market:offerDialog.time')}
                        value={dayjs(field.value)}
                        onChange={field.onChange}
                        format={'HH:mm'}
                        className={styles.textField}
                        slotProps={{
                          textField: {
                            error: !!errors?.stops?.[index]?.time,
                            helperText: (
                              <Box
                                className={styles.errorBox}
                                height={2}
                              >
                                {errors?.stops?.[index]?.time?.message}
                              </Box>
                            ),
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`stops.${index}.timeTo`}
                    render={({ field }) => (
                      <TimeField
                        ampm={false}
                        label={t('Market:offerDialog.timeTo')}
                        value={dayjs(field.value)}
                        onChange={field.onChange}
                        format={'HH:mm'}
                        className={styles.textField}
                        slotProps={{
                          textField: {
                            error: !!errors?.stops?.[index]?.timeTo,
                            helperText: (
                              <Box
                                className={styles.errorBox}
                                height={2}
                              >
                                {errors?.stops?.[index]?.timeTo?.message}
                              </Box>
                            ),
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Box>
          </LocalizationProvider>
        )
      })}
      <Button
        variant={'contained'}
        onClick={() => {
          append(getMyMarketOffersStopDefaults(appConfig.REGION) as ICreateStop)
          setCountryCodes((prev) => [...prev, country ?? 'US'])
        }}
      >
        {t('Market:offerDialog.addStop')}
      </Button>
      <Typography>{t('Market:offerDialog.details')}</Typography>
      <Controller
        control={control}
        name={'type'}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t('Market:offerDialog.type')}
            error={!!errors?.type}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.type?.message}
              </Box>
            }
          >
            <MenuItem value={'PTL'}>{t('Market:offerDialog.typePTL')}</MenuItem>
            <MenuItem value={'LTL'}>{t('Market:offerDialog.typeLTL')}</MenuItem>
            <MenuItem value={'FTL'}>{t('Market:offerDialog.typeFTL')}</MenuItem>
          </TextField>
        )}
      />
      <Controller
        control={control}
        name={'loadingMeters'}
        render={({ field }) => (
          <TextField
            {...field}
            type={'number'}
            className={styles.textField}
            label={t('Fleet:createLoadDialog.loadingMeters')}
            error={!!errors?.loadingMeters}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.loadingMeters?.message}
              </Box>
            }
          />
        )}
      />
      <Stack
        direction={'row'}
        spacing={2}
      >
        <Controller
          control={control}
          name={'publishedPrice'}
          render={({ field }) => (
            <TextField
              {...field}
              type={'number'}
              className={styles.textField}
              label={t('Market:offerDialog.publishedPrice')}
              error={!!errors?.publishedPrice}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.publishedPrice?.message}
                </Box>
              }
            />
          )}
        />
        <Controller
          control={control}
          name={'publishedPriceCurrency'}
          render={({ field }) => (
            <TextField
              {...field}
              select
              className={styles.textField}
              label={t('Market:offerDialog.publishedPriceCurrency')}
              error={!!errors?.publishedPriceCurrency}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.publishedPriceCurrency?.message}
                </Box>
              }
            >
              {/* @ts-ignore */}
              {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                (currency: string) => (
                  <MenuItem value={currency}>{t('Common:currencies.' + currency)}</MenuItem>
                ),
              )}
            </TextField>
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'commodityName'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Market:offerDialog.commodityName')}
            error={!!errors?.commodityName}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.commodityName?.message}
              </Box>
            }
          />
        )}
      />
      <Stack
        direction={'row'}
        spacing={2}
      >
        <Controller
          control={control}
          name={'weight'}
          render={({ field }) => (
            <TextField
              {...field}
              type={'number'}
              className={styles.textField}
              label={t('Market:offerDialog.weight')}
              error={!!errors?.weight}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.weight?.message}
                </Box>
              }
            />
          )}
        />
        <Controller
          control={control}
          name={'weightUnit'}
          render={({ field }) => (
            <TextField
              {...field}
              select
              className={styles.textField}
              label={t('Market:offerDialog.weightUnit')}
              error={!!errors?.weightUnit}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.weightUnit?.message}
                </Box>
              }
            >
              <MenuItem value={'lbs'}>{t('Market:offerDialog.weightUnit_lbs')}</MenuItem>
              <MenuItem value={'kg'}>{t('Market:offerDialog.weightUnit_kg')}</MenuItem>
              <MenuItem value={'t'}>{t('Market:offerDialog.weightUnit_t')}</MenuItem>
            </TextField>
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'equipment'}
        render={({ field }) => (
          <TrailerSelect
            {...field}
            error={errors.equipment}
          />
        )}
      />
      <Controller
        control={control}
        name={'description'}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            rows={3}
            label={t('Market:offerDialog.description')}
            error={!!errors?.description}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.description?.message}
              </Box>
            }
          />
        )}
      />
      <Controller
        control={control}
        name={'publishExternalPlatforms'}
        render={({ field }) => <IntegrationsField {...field} />}
      />
      <Button
        variant={'contained'}
        type={'submit'}
      >
        {t('Common:create')}
      </Button>
    </Stack>
  )
}

export default CreateOfferDialog
