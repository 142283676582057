import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  TextField,
  Button,
  Container,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { parseError } from '@shared/utils/formatters'
import { useProvideNePasswordForPasswordResetMutation } from 'src/features/users/usersApiSlice'
import useAlert from '@hooks/useAlert'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import useCustomerID from '@/hooks/useCustomerID'
import { useGetCustomerIdQuery } from '@/api/apiSlice'
import { useLocation } from 'react-router'
import { resetSchema } from '@pkg/users/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import './styles.scss'
import Logo from '@assets/img/sokar_logo.svg'

interface NewPasswordRequest {
  id: string
  newPassword: string
  passwordCheck: string
}

const NewPassword: React.FC = () => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const location = useLocation()

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordCheck, setShowPasswordCheck] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<NewPasswordRequest>({
    resolver: yupResolver(resetSchema),
  })

  const { dispatch: dispatchAlert } = useAlert()
  const [resetPassword] = useProvideNePasswordForPasswordResetMutation()

  const customerTag = useCustomerID()
  const { data: customerId } = useGetCustomerIdQuery(customerTag, { skip: !customerTag })

  const handleClickShowPassword = () => setShowPassword((prev) => !prev)
  const handleClickShowPasswordCheck = () => setShowPasswordCheck((prev) => !prev)

  const onSubmit = (data: NewPasswordRequest) => {
    const id = location.pathname.split('/').pop()
    if (!customerId || !id) {
      return
    }
    data.id = id
    resetPassword(data)
      .unwrap()
      .then((res) => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Root:resetPassword.newPasswordSuccess'),
            severity: 'success',
          },
        })
      })
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
  }

  return (
    <Container
      className='register-container'
      sx={{ mt: 4 }}
    >
      <img
        src={Logo}
        alt="application's logo"
        className='register-logo'
      />
      <Typography
        component='h1'
        variant='h5'
        className='reset-title'
        sx={{ color: appTheme.palette.primary.main, mb: 3 }}
      >
        {t('Root:resetPassword.NewPasswordTitle')}
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className='register-form'
      >
        <Controller
          control={control}
          name={'newPassword'}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Root:resetPassword.newPassword')}
              type={showPassword ? 'text' : 'password'}
              variant='outlined'
              fullWidth
              margin='normal'
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={'passwordCheck'}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Root:resetPassword.repeatPassword')}
              type={showPasswordCheck ? 'text' : 'password'}
              variant='outlined'
              fullWidth
              margin='normal'
              error={!!errors.passwordCheck}
              helperText={errors.passwordCheck?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPasswordCheck}
                      edge='end'
                    >
                      {showPasswordCheck ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className='register-button'
          sx={{ mt: 2 }}
          fullWidth
        >
          {t('Root:resetPassword.btn')}
        </Button>
      </form>
      <Typography>
        <a
          style={{ color: appTheme.palette.primary.main }}
          className='newpassword-title'
          href='/signin'
        >
          {t('Root:resetPassword.back')}
        </a>
      </Typography>
    </Container>
  )
}

export default NewPassword
