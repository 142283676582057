import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import RequireAuth from 'src/features/auth/RequireAuth'

import AuthLayout from '@/app/root/layouts/authLayout'
// Layouts
import Layout from '@/app/root/layouts/Layout'

import Companies from '../companies'
// Modules
import Fleet from '../fleet'
import ReserveFleetObjectView from '../fleet/views/ReserveFleetObjectView'
import Market from '../market'
import Management from '../managment'
import Archive from '../archive'
import Accounting from '../accounting'
// Unauthenticated views
import SignIn from './views/SignIn'
import Register from './views/Register'
import PasswordReset from './views/resetPassword'
import NewPassword from './views/NewPassword'
// Authenticated views
import Settings from './views/Settings'

const Root = () => {
  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route
            path={'/signin'}
            element={<SignIn />}
          />
          <Route
            path={'/register'}
            element={<Register />}
          />
          <Route
            path={'/password/reset'}
            element={<PasswordReset />}
          />
          <Route
            path={'/password/reset/*'}
            element={<NewPassword />}
          />
        </Route>
        <Route element={<RequireAuth />}>
          <Route element={<Layout />}>
            <Route
              path={'/companies'}
              element={<Companies />}
            />
          </Route>
        </Route>

        <Route element={<RequireAuth />}>
          <Route element={<Layout />}>
            <Route
              path={'/market/*'}
              element={<Market />}
            />
          </Route>
        </Route>

        <Route element={<RequireAuth />}>
          <Route element={<Layout />}>
            <Route
              path={'/settings'}
              element={<Settings />}
            />
          </Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={['dispatcher']} />}>
          <Route element={<Layout />}>
            <Route
              path={'/fleet/*'}
              element={<Fleet />}
            />
          </Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={['accountant', 'employee']} />}>
          <Route element={<Layout />}>
            <Route
              path={'/accounting/*'}
              element={<Accounting />}
            />
          </Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={['dispatcher', 'accountant']} />}>
          <Route element={<Layout />}>
            <Route
              path={'/archive/*'}
              element={<Archive />}
            />
          </Route>
        </Route>

        <Route
          path='/fleet/trailer/reserve/:trailerId'
          element={<ReserveFleetObjectView />}
        />
        <Route element={<RequireAuth allowedRoles={['manager']} />}>
          <Route element={<Layout />}>
            <Route
              path={'/management/*'}
              element={<Management />}
            />
          </Route>
        </Route>
        <Route
          path='/*'
          element={
            <Navigate
              to={'/signin'}
              replace
            />
          }
        />
      </Routes>
    </Router>
  )
}

export default Root
