import { t } from 'i18next'
import { array, boolean, object, string } from 'yup'

export const companyValidator = object().shape(
  {
    name: string()
      .required(t('Companies:validatorResponses.nameReq') ?? '')
      .min(3, t('Companies:validatorResponses.nameMin', { characters: 3 }) ?? '')
      .max(20, t('Companies:validatorResponses.nameMax', { characters: 20 }) ?? '')
      .matches(/^[A-Za-z0-9 ]+$/, t('Companies:validatorRespones.noSpecialChars') ?? ''),
    fullName: string()
      .required(t('Companies:validatorResponses.fullNameReq') ?? '')
      .min(5, t('Companies:validatorResponses.fullNameMin', { characters: 5 }) ?? ''),
    vatId: string().when('regon', {
      is: (vatId: string) => !vatId || vatId.length === 0,
      then: string()
        .required(t('Companies:validatorResponses.vatIdReq') ?? '')
        .min(3, t('Companies:validatorResponses.vatIdMin', { characters: 3 }) ?? '')
        .max(15, t('Companies:validatorResponses.vatIdMax', { characters: 15 }) ?? '')
        .matches(/^[0-9]+$/, 'Companies:validatorResponses.vatIdDigits'),
      otherwise: string(),
    }),
    regon: string().when('vatId', {
      is: (regon: string) => !regon || regon.length === 0,
      then: string()
        .required(t('Companies:validatorResponses.regonReq') ?? '')
        .min(3, t('Companies:validatorResponses.regonMin', { characters: 3 }) ?? ''),
      otherwise: string(),
    }),
    phone: string()
      .required(t('Companies:validatorResponses.phoneReq') ?? '')
      .max(15, t('Companies:validatorResponses.phoneMax', { characters: 15 }) ?? '')
      .matches(/^[+][\d\s]+$/, 'Companies:validatorResponses.phoneDigits'),
    email: string()
      .required(t('Companies:validatorResponses.emailReq') ?? '')
      .email(t('Companies:validatorResponses.emailEmail') ?? ''),
    countryCode: string().required(t('Companies:validatorResponses.countryCodeReq') ?? ''),
    city: string().required(t('Companies:validatorResponses.cityReq') ?? ''),
    street: string().required(t('Companies:validatorResponses.streetReq') ?? ''),
    zipCode: string().required(t('Companies:validatorResponses.zipCodeReq') ?? ''),
    correspondence: string().required(t('Companies:validatorResponses.correspondenceReq') ?? ''),
    categories: array()
      .of(string())
      .min(1, t('Companies:validatorResponses.categoriesMin') ?? '')
      .required(t('Companies:validatorResponses.categoriesReq') ?? ''),
    freightCurrency: string().required(t('Companies:validatorResponses.freightCurrencyReq') ?? ''),
    paymentCurrency: string().required(t('Companies:validatorResponses.paymentCurrencyReq') ?? ''),
    defaultVat: string().required(t('Companies:validatorResponses.defaultVatReq') ?? ''),
    incomingPaymentValue: string().required(
      t('Companies:validatorResponses.incomingPaymentValueReq') ?? '',
    ),
    incomingPaymentCurrency: string().required(
      t('Companies:validatorResponses.incomingPaymentCurrencyReq') ?? '',
    ),
    outgoingPaymentValue: string().required(
      t('Companies:validatorResponses.outgoingPaymentValueReq') ?? '',
    ),
    outgoingPaymentCurrency: string().required(
      t('Companies:validatorResponses.outgoingPaymentCurrencyReq') ?? '',
    ),
    incomingInvoicePaymentDays: string().required(
      t('Companies:validatorResponses.incomingInvoicePaymentDaysReq') ?? '',
    ),
    outgoingInvoicePaymentDays: string().required(
      t('Companies:validatorResponses.outgoingInvoicePaymentDaysReq') ?? '',
    ),

    isSuspended: boolean(),
    suspendedDescription: string().when('isSuspended', {
      is: true,
      then: string().required(t('Companies:validatorResponses.suspendedDescriptionReq') ?? ''),
    }),
  },
  [['vatId', 'regon']],
)
