import styles from './trailer.module.scss'
import { useState, useEffect } from 'react'
import { t } from 'i18next'
import { ITheme } from '@pkg/sokarUI'
import { FieldError } from 'react-hook-form'
import { useGetTrailerCategoriesQuery } from '@features/equipment/equipmentApiSlice'

import {
  MenuItem,
  Stack,
  InputLabel,
  Box,
  Chip,
  Skeleton,
  Select,
  FormControl,
  useTheme,
} from '@mui/material'

interface TrailerSelectProps {
  value: string | string[]
  onChange: (arg: string) => void
  sx?: object
  error?: FieldError
}

const TrailerSelect = ({ ...props }: TrailerSelectProps): JSX.Element => {
  const appTheme: ITheme = useTheme()

  const { data: trailerCategories, isFetching: isTrailerCategoriesFetching } =
    useGetTrailerCategoriesQuery(null)

  const [selectedTrailers, setSelectedTrailers] = useState<string[]>([])
  const handleTrailerSelection = (value: string | string[]): void => {
    setSelectedTrailers(() => {
      if (typeof value == 'string') {
        return value.split(',')
      }
      return value
    })
    props.onChange(typeof value == 'string' ? value : value.join(','))
  }

  useEffect(() => {
    if (props.value && typeof props.value == 'string') {
      setSelectedTrailers(props.value.split(','))
    }
  }, [props.value])

  if (isTrailerCategoriesFetching) {
    return <Skeleton />
  }

  return (
    <Stack
      direction={'column'}
      sx={props.sx}
    >
      <FormControl>
        <InputLabel id={'trailerInputLabel'}>{t('Common:inputs.trailer')}</InputLabel>
        <Select
          multiple
          labelId={'trailerInputLabel'}
          value={selectedTrailers}
          label={t('Common:inputs.trailer')}
          onChange={(e) => handleTrailerSelection(e.target.value)}
          error={!!props.error}
          renderValue={(selected: any) => (
            <Box className={styles.input}>
              {selected.map((value: any) => (
                <Chip
                  key={value}
                  label={value}
                  color={'primary'}
                  className={styles.chip}
                />
              ))}
            </Box>
          )}
        >
          {trailerCategories?.map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              ({category}) {t(`Fleet:equipment.${category}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        height={2}
        sx={{ color: appTheme.palette.error.main }}
        className={styles.fieldError}
      >
        {props.error?.message}
      </Box>
    </Stack>
  )
}

export default TrailerSelect
