import { useEffect, useReducer, Reducer, MouseEvent, Fragment } from 'react'
import { t } from 'i18next'
import { ITheme } from '@pkg/sokarUI'
import styles from './trailerInfo.module.scss'

import { Chip, Box, Popover, Typography, useTheme } from '@mui/material'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

interface TrailerInfoProps {
  trailers: string[]
}

interface ITrailerInfoState {
  show: boolean
  trailers: string[]
  anchor: HTMLElement | null
}

type TrailerInfoAction =
  | { type: 'ADD'; payload: { trailers: string[] } }
  | { type: 'SHOW'; payload: { anchor: HTMLElement } }
  | { type: 'HIDE' }

const trailerInfoReducer: Reducer<ITrailerInfoState, TrailerInfoAction> = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return {
        ...state,
        trailers: action.payload.trailers,
      }
    case 'SHOW':
      return {
        ...state,
        show: true,
        anchor: action.payload.anchor,
      }
    case 'HIDE':
      return {
        ...state,
        show: false,
        anchor: null,
      }
    default:
      return state
  }
}

const TrailerInfo = ({ ...props }: TrailerInfoProps): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const [state, dispatch] = useReducer(trailerInfoReducer, {
    show: false,
    trailers: [],
    anchor: null,
  })

  useEffect((): void => {
    if (props.trailers) {
      dispatch({
        type: 'ADD',
        payload: {
          trailers: props.trailers,
        },
      })
    }
  }, [props.trailers])

  const handlePopoverShow = (event: MouseEvent<HTMLElement>): void => {
    dispatch({
      type: 'SHOW',
      payload: {
        anchor: event.currentTarget,
      },
    })
  }

  const handlePopoverHide = (_event: MouseEvent<HTMLElement>): void => {
    dispatch({ type: 'HIDE' })
  }

  if (typeof state.trailers != 'string') {
    return (
      <Fragment>
        <Chip
          color={'primary'}
          className={styles.chip}
          label={<MoreHorizIcon />}
          onMouseEnter={handlePopoverShow}
        />
        <Popover
          open={state.show}
          anchorEl={state.anchor}
          onClose={handlePopoverHide}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box
            className={styles.popover}
            onMouseLeave={handlePopoverHide}
            sx={{ backgroundColor: appTheme.palette.background.default }}
          >
            {state.trailers.map((category) => (
              <Typography>
                ({category}) {t(`Fleet:equipment.${category}`)}
              </Typography>
            ))}
          </Box>
        </Popover>
      </Fragment>
    )
  }

  return (
    <Chip
      color={'primary'}
      className={styles.chip}
      label={state.trailers}
    />
  )
}

export default TrailerInfo
