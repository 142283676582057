import { yupResolver } from '@hookform/resolvers/yup'
import { FactCheck } from '@mui/icons-material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Chip, IconButton, Tooltip, useTheme } from '@mui/material'
import { AsYouType } from 'libphonenumber-js'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SideDialog, { ISideDialogRef } from '@/lib/SideDialog'
import { initCompayDialogRequest } from '@/pkg/companies/initializers'
import { ICompany, ICompanyPartial } from '@/pkg/companies/models'
import { companyValidator } from '@/pkg/companies/validators'
import { ITheme } from '@/pkg/sokarUI'

import CompanyForm from './CompanyForm'

interface CompanyDialogProps {
  onCompanyCreate: (data: ICompanyPartial) => void
  onCompanyUpdate: (data: ICompanyPartial) => void
  onCompanyDelete: (id: string) => void
  onCheckCompany: (vatId?: string, countryCode?: string) => void
}

export interface ICompanyDialogRef {
  open: (open: boolean, data?: ICompany) => void
}

const CompanyDialog = forwardRef<ICompanyDialogRef, CompanyDialogProps>(
  ({ onCompanyCreate, onCompanyUpdate, onCompanyDelete, onCheckCompany }, ref) => {
    const { t } = useTranslation()
    const appTheme: ITheme = useTheme()
    const companyDialogRef = useRef<ISideDialogRef>(null)
    const [isSuspended, setIsSuspended] = useState<boolean>(false)
    const [companyInfo, setCompanyInfo] = useState<ICompany | undefined>(undefined)
    const [genCorrespondence, setGenCorrespondence] = useState<string | undefined>('')
    const [correspondanceTouched, setCorrespondanceTouched] = useState<boolean>(false)
    const [vatId, setVatId] = useState<string | undefined>('')
    const [countryCode, setCountryCode] = useState<string | undefined>('')

    const {
      control,
      handleSubmit,
      reset,
      getValues,
      setValue,
      formState: { errors },
    } = useForm({
      defaultValues: initCompayDialogRequest(),
      resolver: yupResolver(companyValidator),
    })

    const handleDeleteCompany = useCallback(
      (id?: string) => {
        if (!id) return
        onCompanyDelete(id)
      },
      [onCompanyDelete],
    )

    const fullDialogActions = useMemo(
      () => (
        <>
          <Tooltip
            title={t('Companies:dialog.checkTaxId')}
            placement={'left'}
            arrow={true}
          >
            <IconButton
              sx={{ color: appTheme.palette.text.light }}
              onClick={() => {
                onCheckCompany(vatId, countryCode)
              }}
            >
              <FactCheck />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t('Companies:dialog.delete')}
            placement={'left'}
            arrow={true}
          >
            <IconButton
              sx={{ color: appTheme.palette.text.light }}
              onClick={() => handleDeleteCompany(companyInfo?.id)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
      [
        appTheme.palette.text.light,
        companyInfo?.id,
        countryCode,
        handleDeleteCompany,
        onCheckCompany,
        t,
        vatId,
      ],
    )

    const partialDialogActions = useMemo(
      () => (
        <Tooltip
          title={t('Companies:dialog.checkTaxId')}
          placement={'left'}
          arrow={true}
        >
          <IconButton
            sx={{ color: appTheme.palette.text.light }}
            disabled={!vatId || !countryCode}
            onClick={() => {
              onCheckCompany(vatId, countryCode)
            }}
          >
            <FactCheck />
          </IconButton>
        </Tooltip>
      ),
      [appTheme.palette.text.light, countryCode, onCheckCompany, t, vatId],
    )

    useImperativeHandle(ref, () => ({
      open(isOpen, _data?: ICompany) {
        reset(initCompayDialogRequest())
        setCompanyInfo(undefined)
        setIsSuspended(false)
        setVatId(undefined)
        setCountryCode(undefined)
        if (_data) {
          let formattedPhone: string
          if (_data.phone) {
            formattedPhone = new AsYouType().input(`+${_data.phone}`)
          } else {
            formattedPhone = ''
          }
          const modifiedData = { ..._data, phone: formattedPhone }
          setCompanyInfo(modifiedData)
          setIsSuspended(modifiedData.isSuspended)
          setVatId(_data.vatId)
          setCountryCode(_data.countryCode)
          reset(modifiedData)
        }
        getGeneratedCorrespondence()

        if (!isOpen) {
          companyDialogRef.current?.close()
        } else {
          companyDialogRef.current?.open(_data ?? true)
        }
      },
    }))

    const onSubmit: SubmitHandler<ICompanyPartial> = (data) => {
      const modifiedData = {
        ...data,
        name: data?.name?.trim().replace(/ +(?= )/g, ''),
        phone: data?.phone?.replace('+', '').replace(/\s+/g, ''),
      }

      if (!companyInfo) {
        onCompanyCreate(modifiedData)
      } else {
        onCompanyUpdate({ ...companyInfo, ...modifiedData })
      }
    }

    const getGeneratedCorrespondence = () => {
      const city = getValues('city')
      const street = getValues('street')
      const zipCode = getValues('zipCode')

      if (!city || !street || !zipCode) {
        setGenCorrespondence('')
      } else {
        setCorrespondanceTouched(true)
        setGenCorrespondence(`${street}, ${zipCode} ${city}`)
      }
    }

    const applyGenCorrespondence = () => {
      if (!genCorrespondence) return

      setValue('correspondence', genCorrespondence)
    }

    return (
      <SideDialog
        ref={companyDialogRef}
        title={
          <>
            {companyInfo ? t('Companies:dialog.title.view') : t('Companies:dialog.title.create')}{' '}
            {isSuspended && (
              <Chip
                label={t('Companies:dialog.title.suspended')}
                color='error'
              />
            )}
          </>
        }
        dialogActions={companyInfo ? fullDialogActions : partialDialogActions}
      >
        <CompanyForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          errors={errors}
          getGeneratedCorrespondence={getGeneratedCorrespondence}
          applyGenCorrespondence={applyGenCorrespondence}
          genCorrespondence={genCorrespondence}
          correspondanceTouched={correspondanceTouched}
          isSuspended={isSuspended}
          setIsSuspended={setIsSuspended}
          setVatId={setVatId}
          setCountryCode={setCountryCode}
        />
      </SideDialog>
    )
  },
)

CompanyDialog.displayName = 'CompanyDialog'

export default CompanyDialog
