import {
  useState,
  Suspense,
  lazy,
  LazyExoticComponent,
  useEffect,
  MutableRefObject,
  ChangeEvent,
} from 'react'
import { t } from 'i18next'
import { Tooltip, IconButton, useTheme, Button, TextField, MenuItem, Skeleton } from '@mui/material'
import { ILoad } from '@pkg/loads/models'
import { ITheme } from '@pkg/sokarUI'
import { ICompany } from '@pkg/companies/models'
import styles from './loadDialog.module.scss'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { useUpdateLoadMutation } from '@features/loads/loadsApiSlice'
import { parseError, parsePhoneNumber } from '@shared/utils/formatters'
import useAlert from '@hooks/useAlert'
import { createUpdateLoadRequest } from '@/pkg/loads/converters'
import {
  useCreateDocumentMutation,
  useGetAvailableTemplatesQuery,
  useGetDocumentContentMutation,
} from '@features/documents/documentsApiSlice'

import ArchiveIcon from '@mui/icons-material/Archive'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { convertCompanyToInvoiceDetails } from '@/pkg/documents/utils'
import { CreateFromTemplate, InvoiceDetails } from '@/pkg/documents/requests'
import useOwnUser from '@hooks/useOwnUser'
import { ITemplate } from '@/pkg/documents/models'
import { ISideDialogRef } from '@/lib/SideDialog'
import { IConfirmationDialogRef } from '@/lib/ConfirmationDialog'

type ongoingTransportElement = 'info' | 'edit' | 'history'

export interface OngoingTransportDialogProps {
  data: ILoad | null
  elementRef: MutableRefObject<ISideDialogRef>
  confirmationRef: MutableRefObject<IConfirmationDialogRef>
  refetch: () => void
  handleClose: () => void
}

const OfferDialog: React.FC<OngoingTransportDialogProps> = ({ ...props }) => {
  const appTheme: ITheme = useTheme()
  const [updateLoad] = useUpdateLoadMutation()
  const [generateDocument] = useCreateDocumentMutation()
  const [getDocumentsContent] = useGetDocumentContentMutation()
  const [offerId, setOfferId] = useState<string>('')
  const { dispatch: dispatchAlert } = useAlert()

  const handleStatusChange = (): void => {
    if (!props.data) {
      return
    }

    const loadData = createUpdateLoadRequest(props.data)
    const handleChange = () => {
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Market:response.transportStatusUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Market:offerDialog.changeStatus'),
      text: (
        <TextField
          select
          defaultValue={loadData.statusName}
          className={styles.textField}
          onChange={(e) => {
            loadData.statusName = e.target.value
          }}
        >
          <MenuItem value={'ho-pu'}>{t('Fleet:currentLoads.statuses.ho-pu')}</MenuItem>
          <MenuItem value={'pick'}>{t('Fleet:currentLoads.statuses.pick')}</MenuItem>
          <MenuItem value={'in-trans'}>{t('Fleet:currentLoads.statuses.in-trans')}</MenuItem>
          <MenuItem value={'drop'}>{t('Fleet:currentLoads.statuses.drop')}</MenuItem>
          <MenuItem value={'del'}>{t('Fleet:currentLoads.statuses.del')}</MenuItem>
          <MenuItem value={'tonu'}>{t('Fleet:currentLoads.statuses.tonu')}</MenuItem>
          <MenuItem value={'can'}>{t('Fleet:currentLoads.statuses.can')}</MenuItem>
        </TextField>
      ),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>
          {t('Common:cancel')}
        </Button>,
        <Button onClick={() => handleChange()}>{t('Common:confirm')}</Button>,
      ],
    })
  }

  const handleArchiveLoad = () => {
    const handleArchive = () => {
      if (!props.data) {
        return
      }
      const loadData = createUpdateLoadRequest(props.data)
      loadData.archived = true
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Market:response.transportStatusUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.archiveLoadTitle'),
      text: t('Fleet:confirmationDialog.archiveLoadText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleArchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const TemplateSelect = ({
    category,
    template,
  }: {
    category: string
    template: {
      language: string
      id: string
    }
  }): JSX.Element => {
    const { data: templates, isFetching: _isTemplatesFetching } = useGetAvailableTemplatesQuery({
      category,
    })

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const temp = JSON.parse(e.target.value)
      template.language = temp.language
      template.id = temp.id
    }

    return (
      <TextField
        select
        className={styles.textField}
        onChange={handleChange}
      >
        {templates?.map((temp: ITemplate) => (
          <MenuItem
            key={temp.id}
            value={JSON.stringify(temp)}
          >
            {temp.name} ({String(temp.lang).toUpperCase()})
          </MenuItem>
        ))}
      </TextField>
    )
  }

  const { firstName, lastName, phone } = useOwnUser()
  const handleGenerateRateConfirmation = () => {
    const template = {
      language: '',
      id: '',
    }
    const handleGeneration = () => {
      if (!props.data) {
        return
      }

      const data: Partial<CreateFromTemplate> = {
        templateId: template.id,
        category: 'rateConfirmation',
        loadId: [props.data.id],
        companyId: [props.data.owner.companyId],
      }
      data.invoiceDetails = {
        ...convertCompanyToInvoiceDetails(props.data.carrier as ICompany),
        ...data.invoiceDetails,
        issuerFirstName: firstName,
        issuerLastName: lastName,
        issuerPhone: parsePhoneNumber(phone),
      } as InvoiceDetails
      dispatchAlert({
        type: 'SHOW',
        payload: {
          content: t('Accounting:responses.startingFileGeneration'),
          severity: 'info',
        },
      })

      generateDocument(data as CreateFromTemplate)
        .unwrap()
        .then((res) => {
          getDocumentsContent(res.id)
            .unwrap()
            .then((content) => {
              fetch(`data:application/pdf;base64,${content.content}`)
                .then((res) => res.blob())
                .then((file) => {
                  const url = URL.createObjectURL(file)
                  const a = document.createElement('a')
                  a.href = url
                  a.download = res.id
                  a.style.display = 'none'

                  document.body.appendChild(a)
                  a.click()

                  document.body.removeChild(a)
                  URL.revokeObjectURL(url)
                })
                .catch(() => {
                  dispatchAlert({
                    type: 'SHOW',
                    payload: {
                      content: t('Accounting:responses.failedToDownload'),
                      severity: 'error',
                    },
                  })
                })
                .then(() => {
                  dispatchAlert({
                    type: 'SHOW',
                    payload: {
                      content: String(t('Accounting:responses.fileDownloaded')),
                      severity: 'success',
                    },
                  })
                })
                .then(() => {
                  props.refetch()
                })
            })
        })
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Accounting:responses.invoiceGenerated'),
              severity: 'success',
            },
          })
        })
        .catch((err) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.generateRateConfirmation'),
      text: (
        <TemplateSelect
          category={'rateConfirmation'}
          template={template}
        />
      ),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>
          {t('Common:cancel')}
        </Button>,
        <Button onClick={() => handleGeneration()}>{t('Common:confirm')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Market:ongoingLoadDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:ongoingLoadDialog.edit')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('edit')}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:ongoingLoadDialog.changeStatus')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleStatusChange}
        >
          <TrackChangesIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:ongoingLoadDialog.generateRateConfirmation')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleGenerateRateConfirmation()}
        >
          <NoteAddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:ongoingLoadDialog.archive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleArchiveLoad}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<ongoingTransportElement>('info')
  const getProperElement = (
    key: ongoingTransportElement,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('./edit'))
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    setElementKey('info')
    if (!!props.data) setOfferId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [offerId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
        handleClose={props.handleClose}
      />
    </Suspense>
  )
}

export default OfferDialog
