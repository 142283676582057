import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material'
import { getRegion } from '@shared/utils/formatters'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import appConfig from '@/config'
import { CompanyCategories, ICompany, ICompanyPartial } from '@/pkg/companies/models'
import { countryCodes } from '@/pkg/countryCodes'
import { ITheme } from '@/pkg/sokarUI'
import { AnimatedButton } from '@/shared/components/AnimatedButton/AnimatedButton'
import { PhoneField } from '@/shared/components/PhoneField/PhoneField'

import { onNameChange } from '../utils/onNameChange'

export interface ICompanyForm {
  handleSubmit: UseFormHandleSubmit<Partial<ICompany>, Partial<ICompany>>
  onSubmit: SubmitHandler<ICompanyPartial>
  control: Control<FieldValues>
  errors: FieldErrors<Partial<ICompany>>
  getGeneratedCorrespondence: () => void
  applyGenCorrespondence: () => void
  genCorrespondence?: string
  correspondanceTouched: boolean
  isSuspended: boolean
  setIsSuspended: Dispatch<SetStateAction<boolean>>
  setVatId: Dispatch<SetStateAction<string | undefined>>
  setCountryCode: Dispatch<SetStateAction<string | undefined>>
}

const CompanyForm = ({
  handleSubmit,
  onSubmit,
  control,
  errors,
  getGeneratedCorrespondence,
  applyGenCorrespondence,
  genCorrespondence,
  correspondanceTouched,
  isSuspended,
  setIsSuspended,
  setVatId,
  setCountryCode,
}: ICompanyForm) => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()

  const country: string = localStorage.getItem('country') ?? 'US'
  const countryCodesFormatted = countryCodes as Record<string, string>

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='companies dialog-form'
    >
      <span className='col'>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => onNameChange(e, field.onChange)}
              label={t('Companies:dialog.name')}
              error={!!errors?.name}
              helperText={<Box height={2}>{errors?.name?.message}</Box>}
            />
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='fullName'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Companies:dialog.fullName')}
              error={!!errors?.fullName}
              helperText={<Box height={2}>{errors?.fullName?.message}</Box>}
            />
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='vatId'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e)
                setVatId(e.target.value)
              }}
              label={t('Companies:dialog.vatId')}
              error={!!errors?.vatId}
              helperText={<Box height={2}>{errors?.vatId?.message}</Box>}
            />
          )}
        />
        <Controller
          name='regon'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Companies:dialog.regon')}
              error={!!errors?.regon}
              helperText={<Box height={2}>{errors?.regon?.message}</Box>}
            />
          )}
        />
      </span>
      <span className='col'>
        <Controller
          control={control}
          name={'phone'}
          render={({ field }) => {
            return (
              <PhoneField
                {...field}
                label={t('Companies:dialog.phone').toString()}
                error={!!errors?.phone}
                helperText={<Box height={2}>{errors?.phone?.message}</Box>}
              />
            )
          }}
        />
      </span>
      <span className='col'>
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Companies:dialog.email')}
              error={!!errors?.email}
              helperText={<Box height={2}>{errors?.email?.message}</Box>}
            />
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='countryCode'
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={field.value || null}
              onChange={(_, newValue: string | null) => {
                field.onChange(newValue)
                setCountryCode(newValue ?? undefined)
              }}
              options={Object.keys(countryCodesFormatted)
                .sort((a, b) => countryCodesFormatted[a].localeCompare(countryCodesFormatted[b]))
                .filter((code) => countryCodesFormatted[code] === getRegion(country ?? ''))}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Companies:dialog.countryCode')}
                  error={!!errors?.countryCode}
                  helperText={<Box height={2}>{errors?.countryCode?.message}</Box>}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>{t(`Common:countries.${option}`)}</li>
              )}
              getOptionLabel={(option) => (option ? t(`Common:countries.${option}`) : '')}
              isOptionEqualToValue={(option, value) => option === value}
            />
          )}
        />
        <Controller
          name='city'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e)
                getGeneratedCorrespondence()
              }}
              onBlur={(_) => {
                field.onBlur()
              }}
              label={t('Companies:dialog.city')}
              error={!!errors?.city}
              helperText={<Box height={2}>{errors?.city?.message}</Box>}
            />
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='street'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e)
                getGeneratedCorrespondence()
              }}
              onBlur={(_) => {
                field.onBlur()
              }}
              label={t('Companies:dialog.street')}
              error={!!errors?.street}
              helperText={<Box height={2}>{errors?.street?.message}</Box>}
            />
          )}
        />
        <Controller
          name='zipCode'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e)
                getGeneratedCorrespondence()
              }}
              onBlur={(_) => {
                field.onBlur()
              }}
              label={t('Companies:dialog.zipCode')}
              error={!!errors?.zipCode}
              helperText={<Box height={2}>{errors?.zipCode?.message}</Box>}
            />
          )}
        />
      </span>
      <span className='col button'>
        <AnimatedButton>
          <Tooltip title={t('Companies:dialog.genCorrespondence')}>
            <ExpandCircleDownRoundedIcon
              className='companies dialog-form generate-btn'
              onClick={() => {
                applyGenCorrespondence()
              }}
              color={genCorrespondence ? 'primary' : 'disabled'}
            />
          </Tooltip>
        </AnimatedButton>
      </span>
      <span className='col'>
        <Controller
          name='correspondence'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              InputLabelProps={{ shrink: correspondanceTouched || !!field.value }}
              placeholder={genCorrespondence}
              label={t('Companies:dialog.correspondence')}
              error={!!errors?.correspondence}
              helperText={<Box height={2}>{errors?.correspondence?.message}</Box>}
            />
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='categories'
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
            <FormControl>
              <InputLabel
                sx={{ color: errors?.categories ? appTheme.palette.error.main : undefined }}
              >
                {t('Companies:dialog.categories')}
              </InputLabel>
              <Select
                onChange={onChange}
                onBlur={onBlur}
                value={value && (Array.isArray(value) ? value : [value])}
                name={name}
                ref={_ref}
                multiple
                input={
                  <OutlinedInput
                    label={t('Companies:dialog.categories')}
                    error={!!errors?.categories}
                  />
                }
                renderValue={(selected: CompanyCategories[]) => {
                  return (
                    <Box className='companies dialog-form-chip'>
                      {(Array.isArray(selected) ? [...selected] : [selected]).map(
                        (_value: CompanyCategories) => (
                          <Chip
                            key={_value}
                            label={t(`Companies:dialog.${_value}`)}
                          />
                        ),
                      )}
                    </Box>
                  )
                }}
              >
                <MenuItem value='carrier'>{t('Companies:dialog.carrier')}</MenuItem>
                <MenuItem value='broker'>{t('Companies:dialog.broker')}</MenuItem>
              </Select>
              <Box
                height={2}
                className='companies error'
                sx={{ color: appTheme.palette.error.main }}
              >
                {errors?.categories?.message}
              </Box>
            </FormControl>
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='freightCurrency'
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>{t('Companies:dialog.freightCurrency')}</InputLabel>
              <Select
                label={t('Companies:dialog.freightCurrency')}
                {...field}
              >
                {appConfig[getRegion(country)]?.currencies?.map((currency: string) => (
                  <MenuItem
                    value={currency}
                    key={currency}
                  >
                    {t(`Common:currencies.${currency}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name='paymentCurrency'
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>{t('Companies:dialog.paymentCurrency')}</InputLabel>
              <Select
                label={t('Companies:dialog.paymentCurrency')}
                {...field}
              >
                {appConfig[getRegion(country)]?.currencies?.map((currency: string) => (
                  <MenuItem
                    value={currency}
                    key={currency}
                  >
                    {t(`Common:currencies.${currency}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name='defaultVat'
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>{t('Companies:dialog.defaultVat')}</InputLabel>
              <Select
                label={t('Companies:dialog.defaultVat')}
                {...field}
              >
                <MenuItem value={'0'}>0%</MenuItem>
                <MenuItem value={'23'}>23%</MenuItem>
                <MenuItem value={'n/a'}>{t('Companies:dialog.notApplicable')}</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='incomingPaymentValue'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Companies:dialog.incomingPaymentValue')}
              error={!!errors?.incomingPaymentValue}
              helperText={<Box height={2}>{errors?.incomingPaymentValue?.message}</Box>}
            />
          )}
        />
        <Controller
          name='incomingPaymentCurrency'
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>{t('Companies:dialog.incomingPaymentCurrency')}</InputLabel>
              <Select
                label={t('Companies:dialog.incomingPaymentCurrency')}
                {...field}
              >
                {appConfig[getRegion(country)]?.currencies?.map((currency: string) => (
                  <MenuItem
                    value={currency}
                    key={currency}
                  >
                    {t(`Common:currencies.${currency}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='outgoingPaymentValue'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Companies:dialog.outgoingPaymentValue')}
              error={!!errors?.outgoingPaymentValue}
              helperText={<Box height={2}>{errors?.outgoingPaymentValue?.message}</Box>}
            />
          )}
        />
        <Controller
          name='outgoingPaymentCurrency'
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>{t('Companies:dialog.outgoingPaymentCurrency')}</InputLabel>
              <Select
                label={t('Companies:dialog.outgoingPaymentCurrency')}
                {...field}
              >
                {appConfig[getRegion(country)]?.currencies?.map((currency: string) => (
                  <MenuItem
                    value={currency}
                    key={currency}
                  >
                    {t(`Common:currencies.${currency}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='incomingInvoicePaymentDays'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Companies:dialog.incomingInvoicePaymentDays')}
              error={!!errors?.incomingInvoicePaymentDays}
              helperText={<Box height={2}>{errors?.incomingInvoicePaymentDays?.message}</Box>}
            />
          )}
        />
        <Controller
          name='outgoingInvoicePaymentDays'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Companies:dialog.outgoingInvoicePaymentDays')}
              error={!!errors?.outgoingInvoicePaymentDays}
              helperText={<Box height={2}>{errors?.outgoingInvoicePaymentDays?.message}</Box>}
            />
          )}
        />
      </span>
      <span className='col'>
        <Controller
          name='description'
          control={control}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                label={t('Companies:dialog.description')}
                error={!!errors?.description}
                helperText={<Box height={2}>{errors?.description?.message}</Box>}
              />
            )
          }}
        />
      </span>
      <span className='col'>
        <Controller
          name='isSuspended'
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref: _ref } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={name}
                  onBlur={onBlur}
                  onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    setIsSuspended(checked)
                    onChange(event, checked)
                  }}
                  checked={!!value}
                  inputRef={_ref}
                />
              }
              label={t('Companies:dialog.isSuspended')}
            />
          )}
        />
      </span>
      {isSuspended && (
        <span className='col'>
          <Controller
            name='suspendedDescription'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('Companies:dialog.suspendedDescription')}
                error={!!errors?.suspendedDescription}
                helperText={<Box height={2}>{errors?.suspendedDescription?.message}</Box>}
              />
            )}
          />
        </span>
      )}
      <span className='btn-submit'>
        <Button
          type='submit'
          variant='contained'
          size='large'
        >
          {t('Companies:dialog.save')}
        </Button>
      </span>
    </form>
  )
}

export default CompanyForm
