import { memo, useState, MutableRefObject, useEffect, Fragment } from 'react'
import dayjs from 'dayjs'
import { ISideDialogChildProps, ISideDialogRef } from '@lib/SideDialog'
import { t } from 'i18next'
import { IStop } from '@pkg/loads/models'
import RouteMap from '@lib/RouteMap'
import { ITheme } from '@pkg/sokarUI'
import styles from './carrierLoadDialog.module.scss'
import TrailerInfo from '@lib/TrailerInfo'

import {
  Box,
  Stack,
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableCell,
  useTheme,
  Skeleton,
  Collapse,
  TableBody,
  Typography,
  Avatar,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { parsePhoneNumber } from '@/shared/utils/formatters'
import { useGetUserQuery } from '@/features/users/usersApiSlice'
import appConfig from '@/config'
import { parseError } from '@/shared/utils/formatters'

import StarIcon from '@mui/icons-material/Star'
import { IConfirmationDialogRef } from '@/lib/ConfirmationDialog'
import { useSendInterestMutation } from '@/features/loads/loadsApiSlice'
import useAlert from '@/hooks/useAlert'

interface CarrierLoadDialogProps extends ISideDialogChildProps {
  elementRef: MutableRefObject<ISideDialogRef | null>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
  handleClose: () => void
}

const CarrierLoadDialog = ({ ...props }: CarrierLoadDialogProps): JSX.Element => {
  const [sendInterest] = useSendInterestMutation()
  const appTheme: ITheme = useTheme()
  const { dispatch: dispatchAlert } = useAlert()

  const { data: owner, isSuccess: isOwnerSuccess } = useGetUserQuery(props.data?.owner.id, {
    skip: !props.data?.owner.id,
  })
  const [isStopsOpen, setIsStopsOpen] = useState<boolean>(true)

  const handleShowInterest = (): void => {
    if (!props.data.id) {
      return
    }

    const handleSendMail = (id: string) => {
      sendInterest(id)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Market:offerDialog.interestSend'),
              severity: 'success',
            },
          })
          props.confirmationRef.current?.close()
          props.handleClose()
        })
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Market:offerDialog.showInterestTitle'),
      text: (
        <p className={styles.confirmationDialogText}>{t('Market:offerDialog.showInterestText')}</p>
      ),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleSendMail(props.data.id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions: JSX.Element = (
    <Fragment>
      <Tooltip title={t('Market:offerDialog.showInterest')}>
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleShowInterest}
        >
          <StarIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  )

  useEffect((): void => {
    props.elementRef.current?.setActions(dialogActions)
  }, [props.data])

  if (!props.data) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    )
  }

  const MemoizedRouteMap = memo(RouteMap)

  return (
    <Stack
      direction={'column'}
      spacing={2}
    >
      <Typography variant={'h5'}>{t('Market:carrierLoadDialog.info')}</Typography>
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Box className={styles.mapWrapper}>
          <MemoizedRouteMap offer={props.data} />
        </Box>
        <Typography className={styles.ownerTitle}>{t('Market:carrierLoadDialog.owner')}</Typography>
        <Box className={styles.contact}>
          <Avatar className={styles.avatar} />
          {isOwnerSuccess && (
            <Stack
              direction={'column'}
              spacing={2}
            >
              <Typography component={'h2'}>
                {isOwnerSuccess && owner.firstName} {isOwnerSuccess && owner.lastName}
              </Typography>
              <Typography
                component={'a'}
                href={`tel:${owner.phone.replace(/^0+/, '')}`}
              >
                {parsePhoneNumber(owner.phone)}
              </Typography>
              <Typography
                component={'a'}
                href={`mailto:${props.data?.owner.email}`}
              >
                {props.data?.owner.email}
              </Typography>
            </Stack>
          )}
        </Box>
      </Stack>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.type')}</TableCell>
              <TableCell>{props.data?.type ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.weight')}</TableCell>
              <TableCell>
                {props.data?.weight ?? ''} {props.data?.weightUnit}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.commodityName')}</TableCell>
              <TableCell>{props.data?.commodityName ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.price')}</TableCell>
              <TableCell>
                {props.data?.publishedPrice ?? ''} {props.data?.publishedPriceCurrency ?? ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.trailer')}</TableCell>
              <TableCell>
                <TrailerInfo trailers={props.data?.equipment.split(',') ?? []} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.loadingMeters')}</TableCell>
              <TableCell>{props.data?.loadingMeters ?? '-'}</TableCell>
            </TableRow>
            <TableRow
              onClick={() => setIsStopsOpen(!isStopsOpen)}
              className={styles.extendableTableCell}
            >
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.stops')}</TableCell>
              <TableCell>
                {isStopsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={2}
                className={styles.collapsableTableCell}
              >
                <Collapse
                  in={isStopsOpen}
                  unmountOnExit
                >
                  <Table>
                    <TableBody>
                      {props.data.stops.map((stop: IStop, index: number) => (
                        <Fragment key={index}>
                          <TableRow className={styles.noBorderBottom}>
                            <TableCell variant={'head'}>
                              {stop.countryAbbreviation} {stop.city}
                            </TableCell>
                            <TableCell>
                              {dayjs(stop.date).format(appConfig.DATE_FORMAT)}
                              {!!stop.dateTo && (
                                <> - {dayjs(stop.dateTo).format(appConfig.DATE_FORMAT)}</>
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow className={styles.noBorderTop}>
                            <TableCell variant={'head'}>
                              {t(`Market:carrierLoadDialog.${stop.category}`)}
                            </TableCell>
                            <TableCell>
                              {dayjs(stop.date).format('HH:mm')}
                              {!!stop.dateTo && <> - {dayjs(stop.dateTo).format('HH:mm')}</>}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                      <TableRow>
                        <TableCell variant={'head'}>
                          {t('Market:carrierLoadDialog.description')}
                        </TableCell>
                        <TableCell>
                          {props.data?.description ? props.data?.description : '-'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default CarrierLoadDialog
