import { useState, Suspense, lazy, LazyExoticComponent, useEffect, ComponentType } from 'react'
import { t } from 'i18next'
import useAlert from '@hooks/useAlert'
import styles from './dialog.module.scss'

import { ITheme } from '@pkg/sokarUI'
import { ITruck } from '@pkg/equipment/models'
import { parseError } from '@shared/utils/formatters'
import { createUpdateTruckRequest } from '@pkg/equipment/converters'

import {
  useDeleteTruckMutation,
  useUpdateTruckMutation,
} from '@features/equipment/equipmentApiSlice'

import { Box, Button, Tooltip, IconButton, useTheme, Skeleton } from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import HistoryIcon from '@mui/icons-material/History'
import AddLinkIcon from '@mui/icons-material/AddLink'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

type trailerDialogElements = 'info' | 'edit' | 'history' | 'link'

export interface TruckDialogProps {
  trailerData: ITruck | null
}

const TruckDialog: React.FC<any> = ({ ...props }) => {
  const appTheme: ITheme = useTheme()
  const { dispatch: dispatchAlert } = useAlert()
  const [deleteTruck] = useDeleteTruckMutation()
  const [updateTruck] = useUpdateTruckMutation()

  const [truckId, setTruckId] = useState<string>('')

  const handleDeleteTruck = (id: string) => {
    const handleDelete = (id: string) => {
      deleteTruck(id)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.truckDeleted'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.deleteTruckTitle'),
      text: t('Fleet:confirmationDialog.deleteTruckText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleDelete(id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const handleArchiveTruck = () => {
    const handleArchive = () => {
      if (!props.data) {
        return
      }
      let truck = createUpdateTruckRequest(props.data)
      truck.archived = true
      updateTruck(truck)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.trailerUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.archiveTruckTitle'),
      text: t('Fleet:confirmationDialog.archiveTruckText'),
      actions: [
        <Button
          key={'cancelArchiveTruckAction'}
          onClick={() => props.confirmationRef.current?.close()}
        >
          {t('Common:no')}
        </Button>,
        <Button
          key={'proceedWithArchiveTruckAction'}
          onClick={() => handleArchive()}
        >
          {t('Common:yes')}
        </Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:truckDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.edit')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('edit')}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.createLink')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('link')}
        >
          <AddLinkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:truckDialog.delete')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleDeleteTruck(truckId)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.archive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleArchiveTruck()}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<trailerDialogElements>('info')
  const getProperElement = (
    key: trailerDialogElements,
  ): LazyExoticComponent<ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('./edit'))
      case 'link':
        return lazy(() => import('./link'))
      case 'history':
        return lazy(() =>
          import('@lib/EventsHistory').then((module) => ({
            default: (props: any) => (
              <module.default
                domain={'truck'}
                objectId={props.data.id}
              />
            ),
          })),
        )
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    setElementKey('info')
    if (props.data) {
      setTruckId(props.data.id)
    }
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [truckId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense
      fallback={
        <Box className={styles.suspense}>
          <Skeleton animation={'wave'} />
        </Box>
      }
    >
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
      />
    </Suspense>
  )
}

export default TruckDialog
