import { useState, useRef, useEffect, useCallback } from 'react'
import { t } from 'i18next'
import dayjs from 'dayjs'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import { useGetCarrierLoadQuery, useSearchCarrierLoadsQuery } from '@features/loads/loadsApiSlice'
import appConfig from '@/config'

import { ITableColumn, ITheme } from '@pkg/sokarUI'
import ContentList, { IContentListRef } from '@lib/ContentList'
import OfferCard from './components/OfferCard'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import CarrierLoadDialog from './components/CarrierLoadDialog'
import CarrierLoadOptionCard from './components/CarrierLoadOptionCard'
import ClusteredMap from '@lib/ClusteredMap'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import styles from './carrierMarket.module.scss'

import { Tooltip, Skeleton, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material'

import ReplayIcon from '@mui/icons-material/Replay'
import TrailerInfo from '@/lib/TrailerInfo'
import { ILoad } from '@/pkg/loads/models'
import CountryFlag from '@/lib/CountryFlag'
import ConfirmationDialog, { IConfirmationDialogRef } from '@/lib/ConfirmationDialog'
import { ISearchMarketOffersRequest } from '@/pkg/market/requests'

const columns: ITableColumn[] = [
  {
    label: t('Market:publishedTable.equipment'),
    dataKey: 'equipment',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.weight'),
    dataKey: 'weight',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.publishedPrice'),
    dataKey: 'publishedPrice',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
]

const MarketplaceView = () => {
  const appTheme: ITheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const [isMapView, setIsMapView] = useState<boolean>(false)

  const offerDialogRef = useRef<ISideDialogRef>(null)
  const handleOfferDialogOpen = useCallback((row: ILoad): void => {
    offerDialogRef.current?.open(row)
  }, [])
  const handleOfferDialogClose = useCallback((): void => {
    offerDialogRef.current?.close()
  }, [])

  const freeActions: JSX.Element = (
    <Tooltip
      title={t('Market:publishedTable.refresh')}
      placement={'left'}
      arrow={true}
    >
      <IconButton
        sx={{ color: appTheme.palette.text.light }}
        onClick={() => refresh()}
      >
        <ReplayIcon />
      </IconButton>
    </Tooltip>
  )

  const cellRenderer = (column: ITableColumn, row: ILoad): JSX.Element => {
    if (column.dataKey === 'equipment') {
      return <TrailerInfo trailers={row.equipment.split(',')} />
    }
    if (column.dataKey === 'weight') {
      return (
        <Typography>
          {row.weight} {row.weightUnit}
        </Typography>
      )
    }
    if (column.dataKey === 'publishedPrice') {
      return (
        <Typography>
          {row.publishedPrice} {row.publishedPriceCurrency}
        </Typography>
      )
    }
    if (column.dataKey === 'pickupDate') {
      return <Typography>{dayjs(row.stops[0].date).format(appConfig.DATE_FORMAT)}</Typography>
    }
    if (column.dataKey === 'deliveryDate') {
      return (
        <Typography>
          {dayjs(row.stops[row.stops.length - 1].date).format(appConfig.DATE_FORMAT)}
        </Typography>
      )
    }
    if (column.dataKey === 'pickupLocation') {
      return (
        <div className={styles.containerLocation}>
          <CountryFlag countryName={row.stops[0].countryAbbreviation} />
          <Typography>{`${row.stops[0].countryAbbreviation} ${row.stops[0].city}`}</Typography>
        </div>
      )
    }
    if (column.dataKey === 'deliveryLocation') {
      return (
        <div className={styles.containerLocation}>
          <CountryFlag countryName={row.stops[row.stops.length - 1].countryAbbreviation} />
          <Typography>{`${row.stops[row.stops.length - 1].countryAbbreviation} ${row.stops[row.stops.length - 1].city}`}</Typography>
        </div>
      )
    }

    return <Typography>{row[column.dataKey as keyof ILoad] as string}</Typography>
  }

  const [searchParameters, setSearchParameters] = useState<Partial<ISearchMarketOffersRequest>>(
    {} as Partial<ISearchMarketOffersRequest>,
  )
  const {
    data: loadOffers,
    isSuccess: isOffersSuccess,
    refetch: refetchOffers,
    isFetching: isOffersFetching,
  } = useGetCarrierLoadQuery({ published: true, tags: 'broker-load', ...searchParameters })
  const refresh = useRefetchWithAlert(refetchOffers)

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(loadOffers)
    else ref.current?.open(loadOffers, columns)
  }, [isMobile, loadOffers, columns])

  useEffect(() => {
    if (isOffersSuccess) ref.current?.open(loadOffers, columns)
  }, [isOffersSuccess, isMapView])

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)

  const getProperView = (): JSX.Element => {
    if (isOffersFetching) {
      return <Skeleton />
    }
    if (isMapView) {
      return (
        <ClusteredMap
          data={loadOffers ?? []}
          isDialogOpen={false}
          handleObjectSelect={handleOfferDialogOpen}
          type={'offeredLoads'}
          display={'pickupLocation'}
        />
      )
    }
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          openDialog={handleOfferDialogOpen}
          listComponent={OfferCard}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleOfferDialogOpen}
      />
    )
  }

  return (
    <>
      <CarrierLoadOptionCard
        isMapView={isMapView}
        handleSearch={setSearchParameters}
        handleViewToggle={() => setIsMapView((prev) => !prev)}
      />
      {getProperView()}
      <SideDialog
        ref={offerDialogRef}
        title={t('Market:carrierLoadDialog.title')}
      >
        <CarrierLoadDialog
          handleClose={handleOfferDialogClose}
          elementRef={offerDialogRef}
          confirmationRef={confirmationDialogRef}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default MarketplaceView
