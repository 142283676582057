import { useCallback, useEffect, useReducer, useState, forwardRef } from 'react'
import { t } from 'i18next'
import appConfig from '@/config'
import styles from './companySearch.module.scss'

import { ITheme } from '@pkg/sokarUI'
import { ICompany } from '@pkg/companies/models'
import { ControllerRenderProps, FieldError } from 'react-hook-form'

import {
  useGetCompaniesQuery,
  useSearchCompaniesQuery,
} from '@features/companies/companiesApiSlice'

import {
  Box,
  Table,
  Stack,
  Paper,
  Radio,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  TextField,
  TableBody,
  RadioGroup,
  Typography,
  TableContainer,
  CircularProgress,
  useTheme,
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

interface CompanySearchProps extends ControllerRenderProps {
  isDisabled: boolean
  onChange: (...event: any[]) => void
  error?: FieldError
}

const companyReducer = (_state: { id: string }, action: { type: string }): { id: string } => {
  if (action.type === 'randomizeId') {
    return {
      id: Math.random().toString(36).substring(2, 15),
    }
  }

  throw Error('Unknown action')
}

const CompanySearch = forwardRef<HTMLDivElement, CompanySearchProps>(
  ({ value, isDisabled, onChange, error }, ref) => {
    const appTheme: ITheme = useTheme()
    const [companyId, setCompanyId] = useState<string>('')
    const [searchPhrase, setSearchPhrase] = useState<string>('')
    const [isSearched, setIsSearched] = useState<boolean>(false)

    const [page, setPage] = useState<number>(1)
    const [nextPage, setNextPage] = useState<null | number>(null)
    const [lastDataLen, setLastDataLen] = useState<number>(0)

    const [state, dispatch] = useReducer(companyReducer, {
      id: Math.random().toString(36).substring(2, 15),
    })
    const {
      data: companiesSearch,
      isSuccess,
      refetch: refetchCompanies,
      isFetching: isFetchingSearch,
    } = useSearchCompaniesQuery(
      { phrase: searchPhrase, page, pageSize: appConfig.PAGE_SIZE, key: state.id },
      { skip: !isSearched },
    )

    const {
      data: companies,
      isSuccess: isCompanySuccess,
      isFetching: isFetchingCompanies,
    } = useGetCompaniesQuery(
      { page, pageSize: appConfig.PAGE_SIZE, key: state.id },
      { skip: !companyId || isSearched },
    )

    useEffect(() => {
      if (value) setCompanyId(value)
    }, [value])

    useEffect(() => {
      if (nextPage !== null && !isFetchingSearch && !isFetchingCompanies) {
        setPage(nextPage)
        setNextPage(null)
      }
    }, [nextPage, isFetchingSearch, isFetchingCompanies])

    // TODO this table does not handle pagination on scroll so basically the pagination returns only 1st page

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleEndReached = useCallback(() => {
      if (!isFetchingSearch && !isFetchingCompanies && companies?.length !== lastDataLen) {
        setNextPage(page + 1)
        setLastDataLen(companies?.length ?? 0)
      }
    }, [companies?.length, isFetchingCompanies, isFetchingSearch, lastDataLen, page])

    const handleSearch = (): void => {
      dispatch({ type: 'randomizeId' })
      if (!isSearched) {
        setIsSearched(true)
      } else if (isSuccess) {
        refetchCompanies()
      }
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
      setIsSearched(false)
    }

    return (
      <>
        <TableContainer
          ref={ref}
          component={Paper}
          className={error ? styles.searchWrapperError : styles.searchWrapper}
          sx={{ backgroundColor: appTheme.palette.background.default }}
        >
          <Stack
            direction={'row'}
            spacing={2}
          >
            <TextField
              value={searchPhrase}
              disabled={isDisabled}
              onKeyDown={handleKeyDown}
              className={styles.searchField}
              label={t('Common:UI.search.ownerCompany')}
              onChange={(e) => setSearchPhrase(e.target.value)}
            />
            <Tooltip title={t('Common:search')}>
              <Button
                variant={'contained'}
                onClick={handleSearch}
                disabled={searchPhrase.length == 0 || isDisabled}
              >
                <SearchIcon />
              </Button>
            </Tooltip>
          </Stack>
          <RadioGroup
            name={'carrier'}
            defaultValue={value ?? ''}
            onChange={onChange}
          >
            <Table className={styles.table}>
              <TableBody>
                {(isFetchingCompanies || isFetchingSearch) && (
                  <Box className={styles.loader}>
                    <CircularProgress size={'6rem'} />
                  </Box>
                )}
                {isCompanySuccess &&
                  !isFetchingCompanies &&
                  companies.length > 0 &&
                  companies.map((company: ICompany) => (
                    <TableRow
                      key={company.id}
                      className={styles.tableRow}
                    >
                      <TableCell>
                        <Radio
                          value={company.id}
                          checked={company.id === companyId}
                          disabled={isDisabled}
                        />
                      </TableCell>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>{company.vatId}</TableCell>
                    </TableRow>
                  ))}
                {isSuccess &&
                  !isFetchingSearch &&
                  companiesSearch?.companies?.length > 0 &&
                  companiesSearch?.companies?.map((company: ICompany) => (
                    <TableRow
                      key={company.id}
                      className={styles.tableRow}
                    >
                      <TableCell>
                        <Radio
                          value={company.id}
                          disabled={isDisabled}
                        />
                      </TableCell>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>{company.vatId}</TableCell>
                    </TableRow>
                  ))}
                {isSearched && companiesSearch?.companies?.length == 0 && (
                  <Box className={styles.noMatch}>
                    <Typography>{t('Common:noMatch')}</Typography>
                  </Box>
                )}
              </TableBody>
            </Table>
          </RadioGroup>
        </TableContainer>
        <Typography sx={{ color: appTheme.palette.error.main }}>{error?.message}</Typography>
      </>
    )
  },
)

export default CompanySearch
