import './styles.scss'

import {
  clearSearchCompaniesCache,
  useCheckCompanyQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompaniesQuery,
  useSearchCompaniesQuery,
  useUpdateCompanyMutation,
} from '@features/companies/companiesApiSlice'
import useAlert from '@hooks/useAlert'
import ConfirmationDialog from '@lib/ConfirmationDialog'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Paper, Skeleton, TextField, Typography, useTheme } from '@mui/material'
import { parseError } from '@shared/utils/formatters'
import debounce from 'lodash.debounce'
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableVirtuoso } from 'react-virtuoso'

import appConfig from '@/config'
import { ICompany, ICompanyPartial, IFormattedCompany } from '@/pkg/companies/models'
import { ICreateCompanyRequest } from '@/pkg/companies/requests'
import { ITheme } from '@/pkg/sokarUI'

import {
  CompaniesFixedHeaderContent,
  CompaniesRowContent,
  CompaniesVirtuosoTableComponents,
} from './components/CompaniesTable'
import CompanyCheckDialog, { ICompanyCheckDialogRef } from './components/CompanyCheckDialog'
import CompanyDialog, { ICompanyDialogRef } from './components/CompanyDialog'

const Companies = () => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()

  const companyDialogRef = useRef<ICompanyDialogRef>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const confirmationRef = useRef<any>(null)
  const companyCheckRef = useRef<ICompanyCheckDialogRef>(null)

  const [page, setPage] = useState<number>(1)
  const [searchPage, setSearchPage] = useState<number>(1)
  const [nextPage, setNextPage] = useState<null | number>(null)
  const [nextSearchPage, setNextSearchPage] = useState<null | number>(null)
  const [sendCompanyCheck, setSendCompanyCheck] = useState<boolean>(false)
  const [vatId, setVatId] = useState<string>('')
  const [countryCode, setCountryCode] = useState<string>('')
  const [isCompanyCheckDialogOpen, setCompanyDialogCheckOpen] = useState<boolean>(false)

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    refetch,
    isFetching,
  } = useGetCompaniesQuery({ page, pageSize: appConfig.PAGE_SIZE })

  const { data: companiesSearch, isFetching: isFetchingSearch } = useSearchCompaniesQuery(
    {
      phrase: searchQuery,
      page: searchPage,
      pageSize: appConfig.PAGE_SIZE,
    },
    { skip: searchQuery === '' },
  )

  const { data: companyCheck, isFetching: isFetchingCompanyCheck } = useCheckCompanyQuery(
    {
      vatId,
      countryCode,
    },
    { skip: !vatId || !countryCode || !sendCompanyCheck },
  )

  const isLoading = isLoadingCompanies

  const [createCompany] = useCreateCompanyMutation()
  const [updateCompany] = useUpdateCompanyMutation()
  const [deleteCompany] = useDeleteCompanyMutation()

  const { dispatch: dispatchAlert } = useAlert()

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  useEffect(() => {
    if (nextPage !== null && !isFetching) {
      setPage(nextPage)
      setNextPage(null)
    }
  }, [nextPage, isFetching])

  useEffect(() => {
    if (nextSearchPage !== null && !isFetching) {
      setSearchPage(nextSearchPage)
      setNextSearchPage(null)
    }
  }, [nextSearchPage, isFetching])

  const mapCompaniesToRows = useCallback((): IFormattedCompany[] => {
    if (!companies) return [] as IFormattedCompany[]

    const formattedCompanies: IFormattedCompany[] = []
    for (const company of companies) {
      formattedCompanies.push({
        address: `${String(company.countryCode)} ${company.city}`,
        ...company,
      })
    }
    return formattedCompanies
  }, [companies])

  const mapCompaniesSearchToRows = useCallback((): IFormattedCompany[] => {
    if (!companiesSearch) return [] as IFormattedCompany[]

    const formattedCompanies: IFormattedCompany[] = []
    for (const company of companiesSearch.companies) {
      formattedCompanies.push({
        address: `${String(company.countryCode)} ${company.city}`,
        ...company,
      })
    }
    return formattedCompanies
  }, [companiesSearch])

  const handleEndReached = () => {
    if (!searchQuery && !isFetching && companies?.length === page * appConfig.PAGE_SIZE) {
      setNextPage(page + 1)
    }

    if (
      searchQuery &&
      !isFetchingSearch &&
      companiesSearch?.totalCount &&
      parseInt(companiesSearch?.totalCount) !== companiesSearch?.companies.length &&
      parseInt(companiesSearch?.totalCount) !== 0 &&
      companiesSearch?.companies.length !== 0 &&
      parseInt(companiesSearch?.totalCount) >= searchPage * appConfig.PAGE_SIZE
    ) {
      setNextSearchPage(searchPage + 1)
    }
  }

  const handleCompanyCreate = (data: ICompanyPartial) => {
    if (!data) return
    delete data.id
    const tmp = data
    if (tmp.categories && !Array.isArray(tmp.categories)) {
      tmp.categories = [tmp.categories]
    }
    createCompany(data as ICreateCompanyRequest)
      .unwrap()
      .then(() => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Companies:response.success.create'),
            severity: 'success',
          },
        })
        refetch()
      })
      .then(() => companyDialogRef.current?.open(false, undefined))
      .catch((err) => {
        const error = parseError<object>(err)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
  }

  const handleCompanyUpdate = (data: ICompanyPartial) => {
    if (!data) return
    const tmp = data
    if (tmp.categories && !Array.isArray(tmp.categories)) {
      tmp.categories = [tmp.categories]
    }
    updateCompany(tmp as ICompany)
      .unwrap()
      .then(() => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Companies:response.success.update'),
            severity: 'success',
          },
        })
        refetch()
      })
      .then(() => companyDialogRef.current?.open(false, undefined))
      .catch((err) => {
        const error = parseError<object>(err)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
  }

  const handleCompanyDelete = (id: string) => {
    const handleDelete = (_id: string) => {
      if (!_id) return
      deleteCompany(_id)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Companies:response.success.delete'),
              severity: 'success',
            },
          })
          refetch()
        })
        .then(() => confirmationRef.current?.close())
        .then(() => companyDialogRef.current?.open(false, undefined))
        .catch((err) => {
          const error = parseError<object>(err)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
        })
    }

    confirmationRef.current?.open({
      title: t('Companies:confirmationDialog.deleteCompanyTitle'),
      text: t('Companies:confirmationDialog.deleteCompanyText'),
      actions: [
        <Button onClick={() => confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleDelete(id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target.value) {
      setSearchQuery('')
    } else {
      clearSearchCompaniesCache()
      setSearchQuery(e.target.value)
      setSearchPage(1)
      setNextSearchPage(null)
    }
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 200)
  }, [])

  const handleCheckVatId = (_vatId?: string, _countryCode?: string) => {
    if (!_vatId || !_countryCode) return
    setVatId(_vatId)
    setCountryCode(_countryCode)
    setSendCompanyCheck(true)
    companyCheckRef.current?.open()
    setCompanyDialogCheckOpen(true)
  }

  const handleCloseCompanyCheckDialog = () => {
    setCompanyDialogCheckOpen(false)
  }

  useEffect(() => {
    if (!companyCheck || isFetchingCompanyCheck) return
    companyCheckRef.current?.loadData(companyCheck)
  }, [companyCheck, isFetchingCompanyCheck, isCompanyCheckDialogOpen])

  if (isLoading) {
    const loaders = []
    for (let i = 0; i < 10; i++) {
      loaders.push(
        <Skeleton
          animation='wave'
          key={i}
          sx={{ width: '100%' }}
        />,
      )
    }
    return <Box className='companies page-content loaders'>{loaders}</Box>
  }

  if (!companies) {
    return (
      <Box className='companies page-content error'>
        <Typography>{t('Common:error.noContent')}</Typography>
        <Button
          variant='contained'
          onClick={() => companyDialogRef.current?.open(true, undefined)}
          endIcon={<AddIcon />}
        >
          {t('Companies:createCompany')}
        </Button>
      </Box>
    )
  }

  return (
    <>
      <CompanyDialog
        ref={companyDialogRef}
        onCompanyCreate={handleCompanyCreate}
        onCompanyUpdate={handleCompanyUpdate}
        onCompanyDelete={handleCompanyDelete}
        onCheckCompany={handleCheckVatId}
      />
      <Box className='companies page-content'>
        <Box className='companies page-header'>
          <TextField
            type='search'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (!e?.target.value) {
                setSearchQuery('')
                clearSearchCompaniesCache()
              }
              debouncedResults(e)
            }}
            placeholder={t('Companies:search') ?? ''}
          />
          <Button
            variant='contained'
            onClick={() => companyDialogRef.current?.open(true, undefined)}
            endIcon={<AddIcon />}
          >
            {t('Companies:createCompany')}
          </Button>
        </Box>
        <Paper className='companies table'>
          {!!searchQuery && (
            <TableVirtuoso
              data={mapCompaniesSearchToRows()}
              components={CompaniesVirtuosoTableComponents}
              fixedHeaderContent={CompaniesFixedHeaderContent}
              itemContent={(_, row: IFormattedCompany) =>
                CompaniesRowContent(_, row, companyDialogRef, appTheme)
              }
              endReached={handleEndReached}
            />
          )}
          {!searchQuery && (
            <TableVirtuoso
              data={mapCompaniesToRows()}
              components={CompaniesVirtuosoTableComponents}
              fixedHeaderContent={CompaniesFixedHeaderContent}
              itemContent={(_, row: IFormattedCompany) =>
                CompaniesRowContent(_, row, companyDialogRef, appTheme)
              }
              endReached={handleEndReached}
            />
          )}
        </Paper>
      </Box>
      <ConfirmationDialog ref={confirmationRef} />
      <CompanyCheckDialog
        ref={companyCheckRef}
        onClose={handleCloseCompanyCheckDialog}
      />
    </>
  )
}

export default Companies
