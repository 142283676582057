import { useTheme } from '@emotion/react'

import './style.scss'

import { Paper } from '@mui/material'

interface OptionsCardProps {
  children: React.ReactNode
}

const OptionsCard: React.FC<OptionsCardProps> = ({ children, ...props }) => {
  const appTheme: any = useTheme()

  return (
    <Paper
      className={'options_card_wrapper'}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      {children}
    </Paper>
  )
}

export default OptionsCard
