import { useReducer, Reducer, SetStateAction, Dispatch, FormEvent } from 'react'
import { t } from 'i18next'
import OptionsCard from '@lib/OptionsCard'
import styles from './optionCard.module.scss'
import appConfig from '@/config'
import { parseDistance, parseDistanceUnitToSymbol } from '@pkg/market/parsers'

import { Box, Button, TextField, Collapse, InputAdornment } from '@mui/material'

import MapIcon from '@mui/icons-material/Map'
import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import CityField from '@lib/CityField'
import CountrySelect from '@lib/CountrySelect'
import { ICity } from '@pkg/loads/models'
import TrailerSelect from '@/lib/TrailerSelect'
import { ISearchMarketOffersRequest } from '@pkg/market/requests'
import { assert } from '@shared/utils/utilities'

interface ICarrierLoadOptionCardProps {
  isMapView: boolean
  handleSearch: Dispatch<SetStateAction<Partial<ISearchMarketOffersRequest>>>
  handleViewToggle: () => void
}

interface ICarrierMarketOptionCardState {
  isSearchExpanded: boolean
  originCountry: string
  originCity: string
  destinationCountry: string
  destinationCity: string
  searchValues: ISearchMarketOffersRequest
}

const defaultValues: ICarrierMarketOptionCardState = {
  originCountry: 'PL',
  originCity: '',
  destinationCountry: 'PL',
  destinationCity: '',
  isSearchExpanded: false,
  searchValues: {
    'originSearch.deadheadMeters': 150000,
    'originSearch.latitude': null,
    'originSearch.longitude': null,
    'destinationSearch.deadheadMeters': 150000,
    'destinationSearch.latitude': null,
    'destinationSearch.longitude': null,
    equipment: null,
  },
}

type CarrierMarketActions =
  | { command: 'toggleView' }
  | { command: 'toggleExpand' }
  | { command: 'handleValueChange'; payload: Record<string, unknown> }
  | { command: 'handleOriginChange'; payload: ICity }
  | { command: 'handleDestinationChange'; payload: ICity }
  | { command: 'handleChangeOriginDeadhead'; payload: number }
  | { command: 'handleChangeDestinationDeadhead'; payload: number }
  | { command: 'handleEquipmentChange'; payload: string }
  | {
      command: 'clearSearch'
      payload: Dispatch<SetStateAction<Partial<ISearchMarketOffersRequest>>>
    }
  | {
      command: 'handleSearch'
      payload: Dispatch<SetStateAction<Partial<ISearchMarketOffersRequest>>>
    }

const CarrierMarketOptionsCardReducer: Reducer<
  ICarrierMarketOptionCardState,
  CarrierMarketActions
> = (state, action) => {
  switch (action.command) {
    case 'toggleView':
      return state
    case 'toggleExpand':
      return {
        ...state,
        isSearchExpanded: !state.isSearchExpanded,
      }
    case 'handleValueChange':
      return {
        ...state,
        ...action.payload,
      }
    case 'handleOriginChange':
      return {
        ...state,
        originCity: action.payload.name,
        searchValues: {
          ...state.searchValues,
          'originSearch.latitude': action.payload.latitude,
          'originSearch.longitude': action.payload.longitude,
        },
      }
    case 'handleDestinationChange':
      return {
        ...state,
        destinationCity: action.payload.name,
        searchValues: {
          ...state.searchValues,
          'destinationSearch.latitude': action.payload.latitude,
          'destinationSearch.longitude': action.payload.longitude,
        },
      }
    case 'clearSearch':
      action.payload({})

      return defaultValues
    case 'handleSearch':
      for (const key in state.searchValues) {
        if (state.searchValues[key as keyof typeof state.searchValues] === null) {
          if (key === 'originSearch.latitude') {
            delete state.searchValues['originSearch.deadheadMeters']
          }
          if (key === 'destinationSearch.latitude') {
            delete state.searchValues['destinationSearch.deadheadMeters']
          }
          delete state.searchValues[key as keyof typeof state.searchValues]
        }
      }

      action.payload(state.searchValues)

      return {
        ...state,
        isSearchExpanded: false,
      }
    case 'handleChangeOriginDeadhead':
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          'originSearch.deadheadMeters': action.payload,
        },
      }
    case 'handleChangeDestinationDeadhead':
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          'destinationSearch.deadheadMeters': action.payload,
        },
      }
    case 'handleEquipmentChange':
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          equipment: action.payload === '' ? null : action.payload,
        },
      }
    default:
      return state
  }
}

const CarrierLoadOptionCard = ({ ...props }: ICarrierLoadOptionCardProps): JSX.Element => {
  const [state, dispatch] = useReducer(CarrierMarketOptionsCardReducer, defaultValues)

  return (
    <OptionsCard>
      <Box sx={{ flex: 1 }}>
        <Box className={styles.inputRow}>
          <CountrySelect
            value={state.originCountry}
            onChange={(_e, option) => {
              assert(option !== null, 'option cannot be NULL at this point')

              dispatch({
                command: 'handleValueChange',
                payload: { originCountry: option.value },
              })
            }}
          />
          <CityField
            index={0}
            value={state.originCity}
            country={state.originCountry}
            handleValueChange={(_i, city) =>
              dispatch({
                command: 'handleOriginChange',
                payload: city,
              })
            }
          />
          <CountrySelect
            value={state.destinationCountry}
            onChange={(_e, option) => {
              assert(option !== null, 'option cannot be NULL at this point')

              dispatch({
                command: 'handleValueChange',
                payload: { destinationCountry: option.value },
              })
            }}
          />
          <CityField
            index={0}
            value={state.destinationCity}
            country={state.destinationCountry}
            handleValueChange={(_i, city) =>
              dispatch({
                command: 'handleDestinationChange',
                payload: city,
              })
            }
          />
        </Box>
        <Collapse
          in={state.isSearchExpanded}
          timeout='auto'
          unmountOnExit
        >
          <Box className={styles.inputRow}>
            <TextField
              value={parseDistance(
                state.searchValues['originSearch.deadheadMeters'] ?? 0,
                'meters',
                appConfig.DEFAULT_DISTANCE_UNIT,
              )}
              type={'number'}
              label={t('Market:search.originDeadhead')}
              onInput={(e: FormEvent<HTMLInputElement>) => {
                dispatch({
                  command: 'handleChangeOriginDeadhead',
                  payload: parseDistance(
                    Number((e.target as HTMLInputElement).value),
                    appConfig.DEFAULT_DISTANCE_UNIT,
                    'meters',
                  ),
                })
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {parseDistanceUnitToSymbol(appConfig.DEFAULT_DISTANCE_UNIT)}
                  </InputAdornment>
                ),
              }}
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
            />
            <TextField
              value={parseDistance(
                state.searchValues['destinationSearch.deadheadMeters'] ?? 0,
                'meters',
                appConfig.DEFAULT_DISTANCE_UNIT,
              )}
              type={'number'}
              label={t('Market:search.destinationDeadhead')}
              onInput={(e: FormEvent<HTMLInputElement>) => {
                dispatch({
                  command: 'handleChangeDestinationDeadhead',
                  payload: parseDistance(
                    Number((e.target as HTMLInputElement).value),
                    appConfig.DEFAULT_DISTANCE_UNIT,
                    'meters',
                  ),
                })
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {parseDistanceUnitToSymbol(appConfig.DEFAULT_DISTANCE_UNIT)}
                  </InputAdornment>
                ),
              }}
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
            />
          </Box>
        </Collapse>
      </Box>
      <Box>
        <Box className={styles.inputRow}>
          <Button
            variant={'contained'}
            onClick={() => dispatch({ command: 'toggleExpand' })}
          >
            {state.isSearchExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <Button
            variant={'contained'}
            onClick={() => dispatch({ command: 'handleSearch', payload: props.handleSearch })}
          >
            <SearchIcon />
          </Button>
          <Button
            variant={'contained'}
            onClick={() => dispatch({ command: 'clearSearch', payload: props.handleSearch })}
          >
            <SearchOffIcon />
          </Button>
          <Button
            variant={'contained'}
            onClick={props.handleViewToggle}
          >
            {props.isMapView ? <FormatListBulletedIcon /> : <MapIcon />}
          </Button>
        </Box>
        <Collapse
          in={state.isSearchExpanded}
          timeout='auto'
          unmountOnExit
        >
          <TrailerSelect
            value={state.searchValues.equipment?.split(',') ?? []}
            onChange={(v) =>
              dispatch({
                command: 'handleEquipmentChange',
                payload: v,
              })
            }
          />
        </Collapse>
      </Box>
    </OptionsCard>
  )
}

export default CarrierLoadOptionCard
