import {
  useState,
  Suspense,
  lazy,
  LazyExoticComponent,
  useEffect,
  RefObject,
  MutableRefObject,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'

import { b64toBlob } from '@shared/utils/parsers'
import { ISideDialogChildProps, ISideDialogRef } from '@lib/SideDialog'
import { parseError } from '@shared/utils/formatters'
import { Tooltip, IconButton, Button } from '@mui/material'
import useAlert from '@hooks/useAlert'
import {
  useDeleteTrailerMutation,
  useGenerateTrailerQrCodeMutation,
  useUpdateTrailerMutation,
} from '@features/equipment/equipmentApiSlice'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import QrCodeIcon from '@mui/icons-material/QrCode'
import HistoryIcon from '@mui/icons-material/History'
import AddLinkIcon from '@mui/icons-material/AddLink'
import ArchiveIcon from '@mui/icons-material/Archive'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { createUpdateTrailerRequest } from '@pkg/equipment/converters'
import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'

type trailerDialogElements = 'info' | 'edit' | 'history' | 'link'

interface ITrailerDialogProps extends ISideDialogChildProps {
  refetch: () => void
  handleClose: () => void
  elementRef: RefObject<ISideDialogRef>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
}

const TrailerDialog: React.FC<ITrailerDialogProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [trailerId, setTrailerId] = useState<string>('')
  const [generateQR] = useGenerateTrailerQrCodeMutation()
  const [deleteTrailer] = useDeleteTrailerMutation()
  const [updateTrailer] = useUpdateTrailerMutation()
  const { dispatch: dispatchAlert } = useAlert()

  const handleGenerateQrCode = (id: string) => {
    const handleGenerate = (id: string): void => {
      generateQR(id)
        .unwrap()
        .then((response) => {
          const QR = b64toBlob(response.qrCode, 'PNG')
          const url = URL.createObjectURL(QR)
          const a = document.createElement('a')
          a.href = url
          a.download = `${id}.png`
          a.style.display = 'none'

          document.body.appendChild(a)
          a.click()

          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.generatedQR'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .catch((err: any) => {
          const error = parseError<any>(err)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.generateQrTitle'),
      text: t('Fleet:confirmationDialog.generateQrText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleGenerate(id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const handleDeleteTrailer = (id: string) => {
    const handleDelete = (id: string) => {
      deleteTrailer(id)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.trailerDeleted'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.deleteTrailerTitle'),
      text: t('Fleet:confirmationDialog.deleteTrailerText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleDelete(id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const handleArchiveTrailer = () => {
    const handleArchive = () => {
      if (!props.data) {
        return
      }
      let trailer = createUpdateTrailerRequest(props.data)
      trailer.archived = true
      updateTrailer(trailer)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.trailerUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.archiveTrailerTitle'),
      text: t('Fleet:confirmationDialog.archiveTrailerText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleArchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:trailerDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.edit')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('edit')}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.generateQR')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleGenerateQrCode(trailerId)}
        >
          <QrCodeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.createLink')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('link')}
        >
          <AddLinkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.delete')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleDeleteTrailer(trailerId)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailerDialog.archive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleArchiveTrailer()}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<trailerDialogElements>('info')
  const getProperElement = (
    key: trailerDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('./edit'))
      case 'history':
        return lazy(() =>
          import('@lib/EventsHistory').then((module) => ({
            default: (props: any) => (
              <module.default
                domain={'truck'}
                objectId={props.data.id}
              />
            ),
          })),
        )
      case 'link':
        return lazy(() => import('./link'))
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    setElementKey('info')
    if (props.data) setTrailerId(props.data.id)
  }, [props.data])

  useEffect(() => {
    if (!!trailerId) props.elementRef.current?.setActions(dialogActions)
  }, [trailerId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
      />
    </Suspense>
  )
}

export default TrailerDialog
