import { useState } from 'react'
import { t } from 'i18next'
import styles from './optionsCard.module.scss'

import OptionsCard from '@lib/OptionsCard'

import { Button, TextField } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'

interface ITrucksOptionsCardProps {
  handleSearch: (phrase: string, isSuccess: boolean) => void
}

const TrucksOptionsCard = ({ ...props }: ITrucksOptionsCardProps): JSX.Element => {
  const [phrase, setPhrase] = useState<string>('')

  const handleClear = () => {
    setPhrase('')
    props.handleSearch('', false)
  }

  return (
    <OptionsCard>
      <TextField
        value={phrase}
        className={styles.searchField}
        label={t('Fleet:trucksTable.searchTitle')}
        onChange={(e) => setPhrase(e.target.value)}
      />
      <Button
        variant={'contained'}
        onClick={() => props.handleSearch(phrase, true)}
        disabled={phrase === ''}
      >
        <SearchIcon />
      </Button>
      <Button
        variant={'contained'}
        onClick={() => handleClear()}
        disabled={phrase === ''}
      >
        <SearchOffIcon />
      </Button>
    </OptionsCard>
  )
}

export default TrucksOptionsCard
