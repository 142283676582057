import { useState, Suspense, lazy, LazyExoticComponent, useEffect, FC } from 'react'
import { t } from 'i18next'
import { useUpdateLoadMutation } from '@/features/loads/loadsApiSlice'
import { ISideDialogChildProps } from 'src/lib/SideDialog'
import useAlert from '@hooks/useAlert'
import { createUpdateLoadRequest } from '@pkg/loads/converters'
import { parseError } from '@shared/utils/formatters'
import { ITheme } from '@pkg/sokarUI'
import styles from './loadDialog.module.scss'

import {
  Box,
  Tooltip,
  IconButton,
  Button,
  TextField,
  Skeleton,
  MenuItem,
  useTheme,
} from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import HistoryIcon from '@mui/icons-material/History'
import PaymentsIcon from '@mui/icons-material/Payments'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'

interface LoadDialogProps extends ISideDialogChildProps {
  elementRef: any
  confirmationRef: any
  refetch: () => void
  handleClose: () => void
}

type loadDialogElements = 'info' | 'edit' | 'history'

const LoadDialog: FC<LoadDialogProps> = ({ ...props }) => {
  const appTheme: ITheme = useTheme()
  const [updateLoad] = useUpdateLoadMutation()
  const [loadId, setLoadId] = useState<string>('')
  const [elementKey, setElementKey] = useState<loadDialogElements>('info')
  const { dispatch: dispatchAlert } = useAlert()

  const handleStatusChange = () => {
    let loadData = createUpdateLoadRequest(props.data)
    const handleChange = () => {
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Market:response.transportStatusUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Market:offerDialog.changeStatus'),
      text: (
        <TextField
          select
          defaultValue={loadData.statusName}
          className={styles.textField}
          onChange={(e) => {
            loadData.statusName = e.target.value
          }}
        >
          <MenuItem value={'ho-pu'}>{t('Fleet:currentLoads.statuses.ho-pu')}</MenuItem>
          <MenuItem value={'pick'}>{t('Fleet:currentLoads.statuses.pick')}</MenuItem>
          <MenuItem value={'in-trans'}>{t('Fleet:currentLoads.statuses.in-trans')}</MenuItem>
          <MenuItem value={'drop'}>{t('Fleet:currentLoads.statuses.drop')}</MenuItem>
          <MenuItem value={'del'}>{t('Fleet:currentLoads.statuses.del')}</MenuItem>
          <MenuItem value={'tonu'}>{t('Fleet:currentLoads.statuses.tonu')}</MenuItem>
          <MenuItem value={'can'}>{t('Fleet:currentLoads.statuses.can')}</MenuItem>
          <MenuItem value={'trck-brk'}>{t('Fleet:currentLoads.statuses.trck-brk')}</MenuItem>
          <MenuItem value={'tch-iss'}>{t('Fleet:currentLoads.statuses.tch-iss')}</MenuItem>
        </TextField>
      ),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>
          {t('Common:cancel')}
        </Button>,
        <Button onClick={() => handleChange()}>{t('Common:confirm')}</Button>,
      ],
    })
  }

  const handleArchiveLoad = () => {
    const handleArchive = () => {
      if (!props.data) {
        return
      }
      let loadData = createUpdateLoadRequest(props.data)
      loadData.archived = true
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.loadUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.archiveLoadTitle'),
      text: t('Fleet:confirmationDialog.archiveLoadText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleArchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const handleSendToInvoice = () => {
    const handleSend = () => {
      if (!props.data) {
        return
      }
      let loadData = createUpdateLoadRequest(props.data)
      loadData.invoiceStatus = 'ready'
      loadData.tags = ['ready-to-invoice']
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.loadUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.sendToInvoiceTitle'),
      text: t('Fleet:confirmationDialog.sendToInvoiceText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleSend()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:loadDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:loadDialog.edit')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('edit')}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:loadDialog.changeStatus')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleStatusChange()}
        >
          <TrackChangesIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:loadDialog.sendForInvoicing')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleSendToInvoice()}
        >
          <PaymentsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:loadDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:loadDialog.archive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleArchiveLoad()}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const getProperElement = (
    key: loadDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('./edit'))
      case 'history':
        return lazy(() =>
          import('@lib/EventsHistory').then((module) => ({
            default: (subprops: LoadDialogProps) => (
              <module.default
                domain={'load'}
                objectId={subprops.data.id}
              />
            ),
          })),
        )
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    if (!!props.data) setLoadId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [loadId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense
      fallback={
        <Box className={styles.suspense}>
          <Skeleton animation={'wave'} />
        </Box>
      }
    >
      <ProperComponent
        data={props.data}
        refetch={props.refetch}
        handleClose={props.handleClose}
      />
    </Suspense>
  )
}

export default LoadDialog
