import { t } from 'i18next'
import { object, number, array, string, date } from 'yup'

import { assert } from '@shared/utils/utilities'

const Validators = {
  missing: t('Market:validators.unexpectedError'),
  specifyNumber: t('Market:validators.specifyNumber'),
  required: t('Market:validators.fieldRequired'),
  positive: t('Market:validators.mustBePositive'),
  earlyDateTo: t('Market:validators.earlyDateTo'),
  earlyDelivery: t('Market:validators.earlyDelivery'),
}

export const usMyMarketOffersValidator = object().shape({
  weight: number()
    .typeError(Validators.specifyNumber)
    .required(Validators.required)
    .positive(Validators.positive),
  publishedPrice: number()
    .typeError(Validators.specifyNumber)
    .required(Validators.required)
    .positive(Validators.positive),
  equipment: string().required(Validators.required),
  stops: array()
    .of(
      object().shape({
        countryAbbreviation: string().required(Validators.required),
        city: string().required(Validators.required),
        category: string().required(Validators.required),
        date: string().required(Validators.required),
        dateTo: string()
          .required(Validators.required)
          .test('isDateAfterOrEqualToStartingPoint', Validators.earlyDateTo, function (value) {
            if (!value) return false
            const { date } = this.parent
            return new Date(value) >= new Date(date)
          }),
        time: string().required(Validators.required),
        timeTo: string()
          .required(Validators.required)
          .test('isDateAfterOrEqualToStartingPoint', Validators.earlyDateTo, function (value) {
            if (!value) return false
            const { time } = this.parent
            return new Date(value) >= new Date(time)
          }),
      }),
    )
    .test(
      'pickupAfterDelivery',
      t('Market:validators.pickupAfterDeliveryConstraint') ?? '',
      function (stops) {
        if (!stops) return false
        const sortedStops = stops.slice().sort((a, b) => {
          assert(typeof a.date === 'string', "stop's date cannot be undefined at this moment")
          const dateA = new Date(a.date as string).getTime()
          assert(typeof b.date === 'string', "stop's date cannot be undefined at this moment")
          const dateB = new Date(b.date as string).getTime()
          return dateA - dateB
        })

        if (sortedStops[0].category !== 'P' && sortedStops[stops.length - 1].category !== 'D') {
          return this.createError({
            path: `stops[${stops.length - 1}].date`,
            message: Validators.earlyDelivery,
          })
        }
        return true
      },
    ),
})

// Just moved, not part of this changes; TODO(Michal): adjust this as see fit
export const marketValidators = object().shape({
  originCords: array()
    .of(number())
    .required(t('Market:validatorResponses.originCordsReq') ?? ''),
  originRadius: number()
    .required(t('Market:validatorResponses.radiusReq') ?? '')
    .min(0, t('Market:validatorResponses.radiusMin') ?? ''),
  originRadiusUnit: string().required(t('Market:validatorResponses.radiusUnitReq') ?? ''),
  originAppointmentStart: date().required(
    t('Market:validatorResponses.originAppointmentStartReq') ?? '',
  ),
  originAppointmentEnd: date().required(
    t('Market:validatorResponses.originAppointmentEndReq') ?? '',
  ),

  destinationCords: array()
    .of(number())
    .required(t('Market:validatorResponses.destinationCordsReq') ?? ''),
  destinationRadius: number()
    .required(t('Market:validatorResponses.radiusReq') ?? '')
    .min(0, t('Market:validatorResponses.radiusMin') ?? ''),
  destinationRadiusUnit: string().required(t('Market:validatorResponses.radiusUnitReq') ?? ''),
  destinationAppointmentStart: date().required(
    t('Market:validatorResponses.destinationAppointmentStartReq') ?? '',
  ),
  destinationAppointmentEnd: date().required(
    t('Market:validatorResponses.destinationAppointmentEndReq') ?? '',
  ),
  equipmentType: string().required(t('Market:validatorResponses.equipmentTypeReq') ?? ''),
  mode: string().required(t('Market:validatorResponses.modeReq') ?? ''),
})
