import { object, number, array, string } from 'yup'
import { t } from 'i18next'
import { assert } from '@/shared/utils/utilities'

const Validators = {
  missing: t('Market:validators.unexpectedError'),
  specifyNumber: t('Market:validators.specifyNumber'),
  required: t('Market:validators.fieldRequired'),
  positive: t('Market:validators.mustBePositive'),
  earlyDateTo: t('Market:validators.earlyDateTo'),
  earlyDelivery: t('Market:validators.earlyDelivery'),
}

export const euMyMarketOffersValidator = object().shape({
  weight: number()
    .typeError(Validators.specifyNumber)
    .required(Validators.required)
    .positive(Validators.positive),
  publishedPrice: number()
    .typeError(Validators.specifyNumber)
    .required(Validators.required)
    .positive(Validators.positive),
  loadingMeters: number()
    .typeError(Validators.specifyNumber)
    .required(Validators.required)
    .positive(Validators.positive),
  equipment: string().required(Validators.required),
  stops: array()
    .of(
      object().shape({
        countryAbbreviation: string().required(Validators.required),
        city: string().required(Validators.required),
        category: string().required(Validators.required),
        date: string().required(Validators.required),
        dateTo: string()
          .required(Validators.required)
          .test('isDateAfterOrEqualToStartingPoint', Validators.earlyDateTo, function (value) {
            if (!value) return false
            const { date } = this.parent
            return new Date(value) >= new Date(date)
          }),
        time: string().required(Validators.required),
        timeTo: string()
          .required(Validators.required)
          .test('isDateAfterOrEqualToStartingPoint', Validators.earlyDateTo, function (value) {
            if (!value) return false
            const { time } = this.parent
            return new Date(value) >= new Date(time)
          }),
      }),
    )
    .test('pickupAfterDelivery', Validators.earlyDelivery, function (stops) {
      if (!stops) return false

      const sortedStops = stops.slice().sort((a, b) => {
        assert(typeof a.date === 'string', "stop's date cannot be undefined at this moment")
        const dateA = new Date(`${a.date} ${a.time}`).getTime()
        assert(typeof b.date === 'string', "stop's date cannot be undefined at this moment")
        const dateB = new Date(`${b.date} ${b.time}`).getTime()
        return dateA - dateB
      })

      const firstStop = sortedStops[0]
      const lastStop = sortedStops[sortedStops.length - 1]

      const firstStopDate = new Date(`${firstStop.date} ${firstStop.time}`).getTime()
      const lastStopDate = new Date(`${lastStop.date} ${lastStop.timeTo}`).getTime()

      if (firstStopDate > lastStopDate) {
        return this.createError({
          path: `stops[${stops.length - 1}].date`,
          message: Validators.earlyDelivery,
        })
      }

      return true
    }),
})
