import { useState, useRef, useEffect, useCallback, FC } from 'react'
import styles from './trucks.module.scss'
import { t } from 'i18next'
import { ITheme } from '@pkg/sokarUI'
import { ITruck } from '@pkg/equipment/models'
import useOwnUser from '@hooks/useOwnUser'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import { useGetTruckQuery, useSearchTruckQuery } from '@features/equipment/equipmentApiSlice'

import ContentTable, { ITableRef } from '@lib/ContentTable'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import ConfirmationDialog, { IConfirmationDialogRef } from '@lib/ConfirmationDialog'

import TruckCard from './components/truckCard'
import TruckDialog from './components/truckDialog'
import CreateTruckDialog from './components/createTruckDialog'
import TrucksOptionsCard from './components/trucksOptionsCard'

import {
  trucksTableColumns as columns,
  trucksTableCellRenderer as cellRenderer,
} from './view.helpers'

import { Box, Tooltip, Skeleton, IconButton, useMediaQuery, useTheme } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'

const TrucksView: FC = () => {
  const { companyId } = useOwnUser()
  const appTheme: ITheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const createTruckDialogRef = useRef<ISideDialogRef>()
  const handleCreateTruckDialogOpen = (): void => {
    createTruckDialogRef.current?.open({})
  }
  const handleCreateTruckDialogClose = (): void => {
    createTruckDialogRef.current?.close()
  }

  const truckDialogRef = useRef<ISideDialogRef>()
  const handleTruckDialogOpen = useCallback((row: ITruck): void => {
    truckDialogRef.current?.open(row)
  }, [])
  const handleTruckDialogClose = useCallback((): void => {
    truckDialogRef.current?.close()
  }, [])

  const {
    data: trucks,
    refetch: refetchTrucks,
    isSuccess: isTrucksSuccess,
  } = useGetTruckQuery({ companyId: companyId, archived: false }, { skip: false })
  const refresh = useRefetchWithAlert(refetchTrucks)

  const [isTrucksSearched, setIsTrucksSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchTrucks = (phrase: string, isSearched: boolean): void => {
    setIsTrucksSearched(isSearched)
    setSearchPhrase(phrase)
  }
  const { data: searchedTrucks } = useSearchTruckQuery(
    { phrase: searchPhrase, companyId, archived: false },
    { skip: !isTrucksSearched },
  )

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect((): void => {
    if (isTrucksSearched && !isMobile) {
      ref.current?.open(searchedTrucks, columns)
      return
    }
    if (isTrucksSearched && isMobile) {
      listRef.current?.open(searchedTrucks)
      return
    }
    if (isMobile) {
      listRef.current?.open(trucks)
      return
    }
    ref.current?.open(trucks, columns)
  }, [isMobile, trucks, isTrucksSearched, searchedTrucks, columns])

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Fleet:trucksTable.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          id={'createTruckButton'}
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateTruckDialogOpen}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trucksTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const getProperComponent = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          openDialog={handleTruckDialogOpen}
          listComponent={TruckCard}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleTruckDialogOpen}
      />
    )
  }

  if (!isTrucksSuccess) {
    return (
      <Box className={styles.loader}>
        {Array(12)
          .fill(null)
          .map((_, index) => (
            <Skeleton
              key={index}
              animation={'wave'}
            />
          ))}
      </Box>
    )
  }

  return (
    <>
      <TrucksOptionsCard handleSearch={handleSearchTrucks} />
      {getProperComponent()}
      <SideDialog
        ref={createTruckDialogRef}
        title={t('Fleet:createTruckDialog.title')}
      >
        <CreateTruckDialog
          refetch={refetchTrucks}
          handleClose={handleCreateTruckDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={truckDialogRef}
        title={t('Fleet:truckDialog.title')}
      >
        <TruckDialog
          refetch={refetchTrucks}
          elementRef={truckDialogRef}
          confirmationRef={confirmationDialogRef}
          handleClose={handleTruckDialogClose}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default TrucksView
