import dayjs from 'dayjs'
import { t } from 'i18next'

const country: string | null = localStorage.getItem('country')

const today = dayjs()
const tomorrow = dayjs().startOf('day').add(1, 'day')
const nextFull30Min = today
  .minute(Math.ceil(today.minute() / 30) * 30)
  .second(0)
  .millisecond(0)

export const euMyMarketOffersStopDefaults: Partial<any> = {
  city: '',
  date: today,
  dateTo: today,
  time: today.hour(8).minute(0),
  timeTo: today.hour(16).minute(0),
  countryAbbreviation: country?.toUpperCase() ?? 'PL',
  country: t(`Common:countries.${country?.toUpperCase()}` ?? 'PL'),
}

export const euMyMarketOffersDefaults: Partial<any> = {
  type: 'FTL',
  loadingMeters: 13.6,
  publishedPriceCurrency: 'EUR',
  weightUnit: 't',
  stops: [
    {
      city: '',
      category: 'P',
      date: today,
      dateTo: today,
      time: nextFull30Min,
      timeTo: nextFull30Min.add(30, 'minutes'),
      countryAbbreviation: country?.toUpperCase() ?? 'PL',
      country: t(`Common:countries.${country?.toUpperCase()}` ?? 'PL'),
    },
    {
      city: '',
      category: 'D',
      date: tomorrow,
      dateTo: tomorrow,
      time: tomorrow.hour(8).minute(0),
      timeTo: tomorrow.hour(16).minute(0),
      countryAbbreviation: country?.toUpperCase() ?? 'PL',
      country: t(`Common:countries.${country?.toUpperCase()}` ?? 'PL'),
    },
  ],
}
