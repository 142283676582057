import { ICountryConfig } from './pkg/types'

const appConfig = {
  SERVER_URL: '',
  OSRM_SERVER: 'https://osrm.onitsoft.com/',
  PORTAL_API_URL: 'https://portal-api.onitsoft.com/api/',
  PAGE_SIZE: 30,
  DATE_FORMAT: '',
  DOCUMENT_LANGUAGE: '',
  DEFAULT_DISTANCE_UNIT: '',
  DEFAULT_DOCUMENT_LANGUAGE: '',
  REGION: '',
  LANGUAGES: [
    {
      name: 'English',
      code: 'EN',
    },
    {
      name: 'Polski',
      code: 'PL',
    },
  ],
  EU: {
    mapCenter: [54.54, 25.19],
    currencies: ['EUR', 'PLN', 'GBP', 'USD'],
    defaultCurrency: 'EUR',
    dateFormat: 'DD-MM-YYYY',
  } as ICountryConfig,
  NA: {
    mapCenter: [39.5, -98.35],
    currencies: ['USD', 'CAD'],
    defaultCurrency: 'USD',
    dateFormat: 'MM-DD-YYYY',
  } as ICountryConfig,

  //TODO !!! these configs below are just copies of NA, so typescript compiler is satisfied !!!

  SA: {
    mapCenter: [39.5, -98.35],
    currencies: ['USD', 'CAD'],
    defaultCurrency: 'USD',
    dateFormat: 'MM-DD-YYYY',
  } as ICountryConfig,
  AS: {
    mapCenter: [39.5, -98.35],
    currencies: ['USD', 'CAD'],
    defaultCurrency: 'USD',
    dateFormat: 'MM-DD-YYYY',
  } as ICountryConfig,
  AU: {
    mapCenter: [39.5, -98.35],
    currencies: ['USD', 'CAD'],
    defaultCurrency: 'USD',
    dateFormat: 'MM-DD-YYYY',
  } as ICountryConfig,
  AF: {
    mapCenter: [39.5, -98.35],
    currencies: ['USD', 'CAD'],
    defaultCurrency: 'USD',
    dateFormat: 'MM-DD-YYYY',
  } as ICountryConfig,
}

export const IMG_OPTIMIZE_OPTIONS = {
  test: /\.(jpe?g|png|gif|tiff|webp|svg|avif)$/i,
  exclude: undefined,
  include: undefined,
  includePublic: true,
  logStats: true,
  ansiColors: true,
  svg: {
    multipass: true,
    plugins: [
      {
        name: 'preset-default',
        params: {
          overrides: {
            cleanupNumericValues: false,
            removeViewBox: false, // https://github.com/svg/svgo/issues/1128
          },
          cleanupIDs: {
            minify: false,
            remove: false,
          },
          convertPathData: false,
        },
      },
      'sortAttrs',
      {
        name: 'addAttributesToSVGElement',
        params: {
          attributes: [{ xmlns: 'http://www.w3.org/2000/svg' }],
        },
      },
    ],
  },
  png: {
    // https://sharp.pixelplumbing.com/api-output#png
    quality: 100,
  },
  jpeg: {
    // https://sharp.pixelplumbing.com/api-output#jpeg
    quality: 100,
  },
  jpg: {
    // https://sharp.pixelplumbing.com/api-output#jpeg
    quality: 100,
  },
  tiff: {
    // https://sharp.pixelplumbing.com/api-output#tiff
    quality: 100,
  },
  // gif does not support lossless compression
  // https://sharp.pixelplumbing.com/api-output#gif
  gif: {},
  webp: {
    // https://sharp.pixelplumbing.com/api-output#webp
    lossless: true,
  },
  avif: {
    // https://sharp.pixelplumbing.com/api-output#avif
    lossless: true,
  },
  cache: false,
  cacheLocation: undefined,
}

async function loadConfig() {
  try {
    const response = await fetch('/config.json')
    const config = await response.json()
    appConfig.SERVER_URL = `${config.api_url}/api/`
    appConfig.DATE_FORMAT = config.date_format
    appConfig.DOCUMENT_LANGUAGE = config.DEFAULT_DOCUMENT_LANGUAGUE
    appConfig.DEFAULT_DISTANCE_UNIT = config.DEFAULT_DISTANCE_UNIT
    appConfig.REGION = config.REGION
  } catch (error) {
    console.error('Error loading config:', error)
  }
}

// Call loadConfig() to fetch and update the configuration
await loadConfig()

export default appConfig
